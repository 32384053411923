export const getRootDomain = () => {
  const { hostname } = window.location;

  if (hostname === 'localhost' || hostname === '127.0.0.1') {
    return hostname;
  }

  const hostParts = hostname.split('.');

  if (hostParts.length > 2) {
    return `.${hostParts.slice(-2).join('.')}`; // ".lisciostaging.com"
  }

  return hostname;
};
