export const addSmsItem = (menuElements: MenuItem[]): MenuItem[] => {
  const smsItem: MenuItem = {
    label: 'SMS',
    icon_class: 'icon-sms',
    icon: 'icon-sms',
    id: 267,
    panel: 'left_top',
    url: '/sms/',
    is_restricted: false,
    onselect_message: null,
    children: [],
    sort_order: 6,
  };

  const emailsIndex = menuElements.findIndex((item) => item.label === 'Emails');
  const smsIndex = menuElements.findIndex((item) => item.label === 'SMS');

  if (smsIndex !== -1) {
    return menuElements;
  }

  if (emailsIndex !== -1) {
    return [
      ...menuElements.slice(0, emailsIndex + 1),
      smsItem,
      ...menuElements.slice(emailsIndex + 1),
    ];
  }
  return [...menuElements, smsItem];
};
