import {
  Box,
  Divider,
  Stack,
  styled,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { Tabs, Tab } from 'common/Tabs';
import { useCallback, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom-latest';
import liscioLightTheme from 'theme/liscioLight';
import { useRpaDocumentProcessingCountQuery } from 'hooks/rpa-documents-processing-hooks';
import { RpaDocumentStatus } from 'gql/graphql';
import UploadRPAFiles from './components/UploadRPAFiles/UploadRPAFiles';
import { ProcessingRPAFiles } from './components';

const Header = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingRight: theme.spacing(3),
  paddingLeft: theme.spacing(3),
}));

const Body = styled(Stack)(() => ({
  backgroundColor: '#F7F8F8',
  flex: 1,
  overflow: 'hidden',
}));

export const TaxAutomation = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [tab, _setTab] = useState(searchParams.get('tab') || 'import');

  const setTab = useCallback(
    (newTab: string) => {
      _setTab(newTab);
      navigate({
        // eslint-disable-next-line no-restricted-globals
        pathname: location.pathname,
        search: `?tab=${newTab}`,
      });
    },
    [navigate],
  );

  const { data: rpaProcessing, refetch: refetchRpaProcessing } =
    useRpaDocumentProcessingCountQuery(
      {
        status: RpaDocumentStatus.Processing,
      },
      {
        staleTime: 60000,
      },
    );

  const processingCount = rpaProcessing?.rpaDocuments?.totalCount;

  return (
    <ThemeProvider theme={liscioLightTheme}>
      <Stack height="100%">
        <Header>
          <Typography variant="h1">Smart Tax</Typography>
          <Tabs value={tab} noBorder>
            <Tab
              label="Import"
              value="import"
              onClick={() => setTab('import')}
            />
            <Tab
              label={`Processing ${
                processingCount ? `(${processingCount})` : ''
              }`}
              value="processing"
              onClick={() => setTab('processing')}
            />
          </Tabs>
        </Header>
        <Divider />
        <Body>
          {tab === 'import' && (
            <UploadRPAFiles
              onClose={() => {
                setTab('processing');
                refetchRpaProcessing();
              }}
            />
          )}
          {tab === 'processing' && (
            <ProcessingRPAFiles processingCount={processingCount} />
          )}
        </Body>
      </Stack>
    </ThemeProvider>
  );
};
