import MUITab, { TabProps } from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom-latest';

interface IStyledTab {
  component?: typeof Link;
  to?: string;
}

const StyledTab = styled(MUITab)<IStyledTab>({
  textTransform: 'none',
  fontSize: '1.12rem',
  padding: '1rem',
  '&.Mui-selected': {
    color: '#212b36',
  },
});

type LinkTabProps = {
  to: string;
  value: string | number;
} & TabProps;

export const LinkTab = (props: LinkTabProps) => (
  <StyledTab disableRipple {...props} component={Link} />
);
