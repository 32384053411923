import Cookies from 'js-cookie';
import { getRootDomain } from './get-root-domain';

export const deleteIntercomCookies = () => {
  const intercomCookiePrefixes = ['intercom-'];

  const allCookies = Cookies.get();
  const rootDomain = getRootDomain();

  Object.keys(allCookies).forEach((cookieName) => {
    if (
      intercomCookiePrefixes.some((prefix) => cookieName.startsWith(prefix))
    ) {
      Cookies.remove(cookieName, { path: '/', domain: rootDomain });
    }
  });
};

export default deleteIntercomCookies;
