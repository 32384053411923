import MUITab, { TabProps } from '@mui/material/Tab';
import { styled } from '@mui/material/styles';

const StyledTab = styled(MUITab)({
  textTransform: 'none',
  fontSize: '1.12rem',
  padding: '1.5rem',
  '&.Mui-selected': {
    color: '#212b36',
  },
});

export const Tab = (props: TabProps) => <StyledTab disableRipple {...props} />;
