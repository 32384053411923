import { WorkflowRequestItemFragment } from 'gql/graphql';
import { RequestItemDependencyDocument } from './components/RequestItemDependencyDocument';
import { PaymentEstimateDependency } from './components/PaymentEstimateDependency';

export type RequestDependencyItemProps = {
  value: WorkflowRequestItemFragment['dependency'] | undefined;
};

export const RequestItemDependency = ({
  value,
}: RequestDependencyItemProps) => {
  // eslint-disable-next-line no-underscore-dangle
  switch (value?.__typename) {
    case 'Document':
      return (
        <RequestItemDependencyDocument
          document={value as unknown as Document}
        />
      );
    case 'Payable':
      return (
        <RequestItemDependencyDocument
          document={value.document as unknown as Document}
        />
      );
    case 'DynamicDependency':
      return value?.type === 'payment_estimates' ? (
        <PaymentEstimateDependency fetchUrl={value.readUrl} />
      ) : null;
    default:
      return null;
  }
};
