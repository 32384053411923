import { createContext, useContext, useEffect, useMemo } from 'react';
import { getAuthSession } from 'auth/session';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Intercom, { hide, show, shutdown } from '@intercom/messenger-js-sdk';
import { useGetApiV5IntercomSettings } from 'api/v5/intercom/intercom';
import { useLocation } from 'react-router-dom-latest';
import deleteIntercomCookies from './utils/delete-intercom-cookies';

export type IntercomContextType = {
  hideIntercom: () => void;
  showIntercom: () => void;
  checkIfIntercomIsAvailable: () => boolean;
};

const DEFAULT_CONTEXT: IntercomContextType = {
  hideIntercom: () => {},
  showIntercom: () => {},
  checkIfIntercomIsAvailable: () => false,
};

export const IntercomContext =
  createContext<IntercomContextType>(DEFAULT_CONTEXT);

export type IntercomProviderProps = {
  children?: React.ReactNode;
};

/**
 * Intercom instance provider
 */
export const IntercomProvider = ({ children }: IntercomProviderProps) => {
  const { isIntercomEnabled } = useFlags();
  const location = useLocation();
  const authSession = getAuthSession();
  const isAuthenticated = !!authSession;

  // useGetApiV5IntercomSettings causes this provider to be re-rendered after logging in - good for re-initializing Intercom
  const { data: intercomSettings } = useGetApiV5IntercomSettings(
    {
      workspace: 'firm',
    },
    { query: { enabled: isAuthenticated } },
  );

  /**
   * Initialize Intercom
   */
  useEffect(() => {
    if (isAuthenticated && intercomSettings && isIntercomEnabled) {
      if (intercomSettings) {
        Intercom({
          app_id: intercomSettings.app_id,
          email: intercomSettings.email,
          user_id: `${intercomSettings.user_id}`,
          user_hash: intercomSettings.user_hash,
          hide_default_launcher: true,
        });
      }
    }
  }, [intercomSettings, isAuthenticated, isIntercomEnabled]);

  /**
   * Shutdown Intercom when user logs out
   */
  useEffect(() => {
    if (isIntercomEnabled && !isAuthenticated) {
      if (window.Intercom) {
        shutdown();
      }
      deleteIntercomCookies();
    }
  }, [location, isIntercomEnabled, isAuthenticated]);

  const ctx = useMemo(
    () => ({
      hideIntercom: hide,
      showIntercom: show,
      checkIfIntercomIsAvailable: () => Boolean(window.Intercom),
    }),
    [],
  );

  return (
    <IntercomContext.Provider value={ctx}>{children}</IntercomContext.Provider>
  );
};

export const useIntercom = () => {
  const context = useContext(IntercomContext);
  return context;
};
