import { MIXPANEL_NAVBAR_CLICK_EVENT } from 'constants/constants';
import { Mixpanel } from 'mixpanel-browser';

const map: Record<string, string> = {
  '/account/new': 'addnew_account',
  '/contact/new': 'addnew_contact',
  '/task/new?type=request_info': 'addnew_task_request',
  '/task/new?type=virtual_meeting': 'addnew_task_meeting',
  '/task/new?type=get_signature': 'addnew_task_signature',
  '/task/new?type=to_do': 'addnew_task_todo',
  '/task/new?type=manage_to_go_items': 'addnew_task_gotoitems',
  '/requests?modal=newRequest': 'addnew_request',
  '/message/new': 'addnew_message',
  '/note/new': 'addnew_note',
  '/fileupload/new': 'addnew_file',
  '/new_esign': 'addnew_edoc',
  '/': 'home',
  '/all_messages': 'inbox',
  '/all_tasks': 'tasks',
  '/requests': 'requests',
  '/smart-tax': 'smart_tax',
  '/emails': 'emails',
  '/accounts': 'accounts',
  '/contacts': 'contacts',
  '/files': 'files',
  '/bulkdownload': 'bulk_download',
  '/bulksignature': 'bulk_signature',
  '/bulkmessage': 'bulk_message',
  '/bulkinvite': 'bulk_invite',
  '/bulkesign': 'bulk_edocs',
  '/bulkreassign': 'bulk_reassign',
  '/esign_list': 'edocs',
  '/notifications': 'notifications',
  '/admin': 'admin_users',
  '/preferences': 'admin_preferences',
  '/templates': 'admin_templates',
  '/integrations': 'admin_integrations',
  '/sms-admin': 'admin_sms',
  '/admin-billing': 'admin_billing',
};

export const sendMixpanelNavbarClickEvent = (
  mixpanel: Mixpanel,
  url: string,
) => {
  const isValidUrl = Object.keys(map).includes(url);

  if (!isValidUrl) {
    return;
  }

  mixpanel.track(MIXPANEL_NAVBAR_CLICK_EVENT, {
    menu_name: map[url],
  });
};
