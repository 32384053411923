import { REQUESTS_PAGE_ROUTES } from 'components/Requests/requests.constants';

export const addTaxAutomationItem = (menuElements: MenuItem[]): MenuItem[] => {
  const taxAutomationItem: MenuItem = {
    label: 'Smart Tax',
    icon_class: 'icon-tax-automation',
    icon: 'icon-tax-automation',
    id: 0,
    panel: 'Smart Tax',
    url: REQUESTS_PAGE_ROUTES.import,
    is_restricted: false,
    onselect_message: null,
    children: [],
  };

  const requestsIndex = menuElements.findIndex(
    (item) => item.label === 'Requests',
  );
  const smartTaxIndex = menuElements.findIndex(
    (item) => item.label === 'Smart Tax',
  );

  if (smartTaxIndex !== -1) {
    return menuElements;
  }

  if (requestsIndex !== -1) {
    return [
      ...menuElements.slice(0, requestsIndex + 1),
      taxAutomationItem,
      ...menuElements.slice(requestsIndex + 1),
    ];
  }
  return [...menuElements, taxAutomationItem];
};
