import { addSmsItem } from './addSmsItem';
import { addTaxAutomationItem } from './addTaxAutomationItem';
import { removeSmsItem } from './removeSmsItem';

export const modifyMenu = (
  menuElements: MenuItem[],
  hasSmsEnabled: boolean,
): MenuItem[] => {
  let modifiedMenu = hasSmsEnabled
    ? addSmsItem(menuElements)
    : removeSmsItem(menuElements);
  modifiedMenu = addTaxAutomationItem(modifiedMenu);
  return modifiedMenu;
};
