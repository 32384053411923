/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  ModalBody,
  Form,
  FormGroup,
  DropdownMenu,
  Input,
  Label,
  Button,
} from 'reactstrap';
import Select, { components } from 'react-select';
import { EditorState, convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import Axios from 'axios';
import TooltipItem from 'common/TooltipItem';
import {
  options,
  removeBreakElementinList,
  generateUniqueHash,
} from 'common/NotesUtilities';
import {
  AlertMessage,
  isUserEmployee,
  getCurrentPath,
  getStorageData,
} from 'utilities/utils';
import TaskFileUpload from 'components/Files/TaskFileUpload_updated';
import {
  SEARCH_TASK_CONTACTS,
  SEARCH_TASK_ACCOUNTS,
  MESSAGES_API_PATH,
  GET_TEMPLATES_FOR_CREATION,
  DISCARD_DRAFT,
  CREATE_DOC_FROM_TEMPLATE_IDS,
  MIXPANEL_MESSAGE_EVENT,
} from 'constants/constants';
import ConfirmationModal from 'common/ConfirmationModal';
import {
  // OptionBoxSelectFieldWithAvatar,
  CheckboxField,
  SelectTemplateFieldOption,
  selectStyles,
  selectTheme,
} from 'common/CustomFields';
import * as TaskApi from 'api/TaskApi';
import * as MessageApi from 'api/MessageApi';
import * as actions from 'actions/MessageActions';
import { trackMixpanelEvent } from 'Mixpanel/mixpanelfn';
import { withRouter } from 'utilities/withRouterWrapper';
import { groupEmpAndContacts } from 'api/TaskApi';
import debounce from 'lodash/debounce';
import TinymceEditor from 'components/Emails/components/TinymceEditior';
import RecipientAndAccountDropdowns from './RecipientAndAccountDropdowns';
// import './message.scss';
class NewModalMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: '',
      documents: [],
      contacts: [],
      accounts: [],
      allContacts: [],
      allAccounts: [],
      contactDetails: '',
      accountId: '',
      contactId: '',
      contatIdPopup: '',
      contactName: '',
      accountName: '',
      recipientType: '',
      inputVal: '',
      allSelectedRecords: [],
      dropdownOpen: false,
      assigneeType: '',
      messageId: '',
      messageThreadId: '',
      draftEdit: false,
      draftId: '',
      errors: {},
      draftStatus: '',
      loading: false,
      type: 'message',
      mounted: false,
      notifyViaText: false,
      apiHitting: false,
      uploadingImages: false,
      sendMessageAfterImageUpload: false,
      initialComment: {},
      selectedOption: null,
      uniqueId: generateUniqueHash(5),
      templateOptions: [],
      templateDocuments: [],
      warningMsgLost: false,
      selectedTemplate: null,
      titleChange: '',
      groupedOptions: [],
      isLoading: true,
      isUserTyping: false,
      // imageUrls: [],
      // noOfImages: 0,
    };
    this.handleCommentChange = this.handleCommentChange.bind(this);
    this.templateDropdown = this.templateDropdown.bind(this);
    this.addSelectedInput = this.addSelectedInput.bind(this);
    this.handleAccountChange = this.handleAccountChange.bind(this);
    this.handleContactChange = this.handleContactChange.bind(this);
    this.handleContactInput = this.handleContactInput.bind(this);
    this.handleAccountInput = this.handleAccountInput.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.discardDraft = this.discardDraft.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.selectInputRef = React.createRef();
  }

  UNSAFE_componentWillMount() {
    const { draftIds } = this.props;
    if (draftIds) {
      this.setState({ draftEdit: true, draftId: draftIds });
    }
    this.timer = null;
  }

  componentDidMount() {
    document.title = 'Create Message';
    // TODO: __HOMEDATA-AUDIT
    const homeDataObj = getStorageData();
    const { match, location, params } = this.props;
    const path = getCurrentPath(location);
    if (path === '/contactdetails/message/:id' && params.id) {
      TaskApi.getAccounts(this, 'notes');
      TaskApi.getContactDetails(this, params.id, 'message');
      // TaskApi.AccountsOfContact(this, match.params.id);
    }
    if (path === '/accountdetails/message/:id' && params.id) {
      const myData = {
        accountId: params.id,
        accountName: this.props.location.state.name,
      };
      this.setState({ contactDetails: { accounts: [] } });
      TaskApi.getContEmpOfAccount(this, myData, 'account', true);
    }
    if (match.pathname === '/message/new/modal') {
      this.setState({ contactDetails: { accounts: [] } });
    }
    // if (match.path === '/contactdetails/message/:id' && match.params.id) {
    //   TaskApi.getContactDetails(this, match.params.id, 'message');
    //   TaskApi.AccountsOfContact(this, match.params.id);
    // } else {
    //   TaskApi.getContactsOfTask(this, { limit: true, message: true }, 'notes');
    //   if (homeDataObj !== null && Object.keys(homeDataObj).length) {
    //     if (homeDataObj.is_employee) {
    //       TaskApi.getAccounts(this, 'notes');
    //       this.setState({ mounted: true });
    //     } else {
    //       TaskApi.AccountsOfContact(this, homeDataObj.contact_id, 'clientmessage');
    //       this.setState({ mounted: true });
    //     }
    //   }
    // }
    TaskApi.getContactsOfTask(this, { limit: true, message: true }, 'notes');
    if (homeDataObj !== null && Object.keys(homeDataObj).length) {
      if (homeDataObj.is_employee) {
        TaskApi.getAccounts(this, 'notes');
        this.setState({ mounted: true });
      } else {
        TaskApi.AccountsOfContact(
          this,
          homeDataObj.contact_id,
          'clientmessage',
        );
        this.setState({ mounted: true });
      }
    }
    const { draftId, draftEdit } = this.state;
    if (draftEdit && draftId) {
      MessageApi.getDraftData(this, draftId);
    }
    if (homeDataObj.is_employee) {
      this.getTemplates();
    }
    window.addEventListener('keypress', (e) => {
      if (e && e.which === 13) {
        e.preventDefault();
      }
    });
  }

  UNSAFE_componentWillReceiveProps() {
    // TODO: __HOMEDATA-AUDIT
    const homeDataObj = getStorageData();
    if (!this.state.mounted) {
      if (homeDataObj !== null && Object.keys(homeDataObj).length) {
        if (homeDataObj.is_employee) {
          TaskApi.getAccounts(this, 'notes');
          this.setState({ mounted: true });
        } else {
          TaskApi.AccountsOfContact(
            this,
            homeDataObj.contact_id,
            'clientmessage',
          );
          this.setState({ mounted: true });
        }
      }
    }
  }

  componentWillUnmount() {
    window.addEventListener('keypress', () => true);
    // const { comment, imageUrls, initialComment } = this.state;
    // if (comment && comment.entityMap && Object.keys(comment.entityMap).length && imageUrls && imageUrls.length) {
    //   Object.keys(comment.entityMap).forEach((e) => {
    //     const { hash } = comment.entityMap[e].data;
    //     imageUrls.forEach((eachImage) => {
    //       if (comment.entityMap[e].data.src && !comment.entityMap[e].data.src.includes('https://')) {
    //         comment.entityMap[e].data.src = '';
    //       }
    //       if (eachImage.hash === hash) {
    //         comment.entityMap[e].data.src = eachImage.url;
    //         comment.entityMap[e].data.id = eachImage.id;
    //       }
    //     });
    //   });
    // }
    // if (comment && comment.entityMap && imageUrls && imageUrls.length) {
    //   const unusedNewImages = imageUrls.filter((e) => {
    //     let notUsed = true;
    //     Object.keys(comment.entityMap).forEach((each) => {
    //       const { hash, id } = comment.entityMap[each].data;
    //       if (hash === e.hash && id) {
    //         notUsed = false;
    //       }
    //     });
    //     return notUsed;
    //   });
    //   if (unusedNewImages.length) {
    //     unusedNewImages.forEach((e) => {
    //       if (e.url) {
    //         Axios.delete(IMAGES + '/' + e.id);
    //       }
    //     });
    //   }
    // }
    // if (comment && comment.entityMap && initialComment && initialComment.entityMap) {
    //   const deletedImages = Object.values(initialComment.entityMap).filter((e) => {
    //     let notUsed = true;
    //     Object.keys(comment.entityMap).forEach((each) => {
    //       const { hash, id } = comment.entityMap[each].data;
    //       if (id && id === e.data.id) {
    //         notUsed = false;
    //       }
    //     });
    //     return notUsed;
    //   });
    //   if (deletedImages.length) {
    //     deletedImages.forEach((e) => {
    //       if (e.data && e.data.id) {
    //         Axios.delete(IMAGES + '/' + e.data.id);
    //       }
    //     });
    //   }
    // }
  }

  onFocus() {
    const { location } = this.props;
    const path = getCurrentPath(location);
    if (path === '/contactdetails/message/:id') {
      this.setState((prevState) => ({
        dropdownOpen: !prevState.dropdownOpen,
        accounts: prevState.allAccounts,
        contacts: prevState.allContacts,
        accountId: '',
        accountName: '',
        errors: {},
      }));
    } else {
      this.setState(
        (prevState) => ({
          dropdownOpen: !prevState.dropdownOpen,
          contacts: prevState.allContacts,
          accounts: prevState.allAccounts,
          groupedOptions: groupEmpAndContacts(prevState.allContacts),
          contactId: '',
          contatIdPopup: '',
          contactName: '',
          errors: {},
        }),
        () => {
          if (this.state.accounts.length === 1) {
            this.setState({
              accountId: this.state.accounts[0].value,
              accountName: this.state.accounts[0].label,
            });
          }
        },
      );
    }
  }

  getTemplates() {
    // this.setState({ loading: true });
    Axios.get(`${GET_TEMPLATES_FOR_CREATION}?template_type=message_template`)
      .then((res) => {
        this.setState({ loading: false });
        if (res.data && res.data.data && res.data.data.length) {
          const { data, default_preferences } = res.data;
          this.setState({
            templateOptions: data,
            loading: false,
            defaultPreferences: default_preferences,
          });
        } else {
          this.setState({
            templateOptions: [],
            loading: false,
          });
        }
      })
      .catch(() => {
        this.setState({
          templateOptions: [],
          loading: false,
        });
      });
  }

  /**
   * This method is used to reset the recipient and account selects
   * This method gets called when clearing a select value
   * @param {*} changeOnly
   */
  getContAcc(changeOnly = 'both') {
    if (changeOnly === 'contacts') {
      this.setState({
        contacts: this.state.allContacts,
        groupedOptions: groupEmpAndContacts(this.state.allContacts),
        contactId: '',
        contatIdPopup: '',
        contactName: '',
        recipientType: '',
        isLoading: false,
        selectedContact: null,
      });
    } else if (changeOnly === 'accounts') {
      this.setState({
        accounts: this.state.allAccounts,
        accountId: null,
        accountName: null,
        selectedContact: null,
      });
    } else {
      this.setState({
        contacts: this.state.allContacts,
        accounts: this.state.allAccounts,
        groupedOptions: groupEmpAndContacts(this.state.allContacts),
        contactId: '',
        contatIdPopup: '',
        accountId: '',
        contactName: '',
        accountName: '',
        recipientType: '',
        selectedContact: null,
      });
    }
  }

  getTemplateDocsId = () => {
    const mydata = {};
    if (this.state.templateDocuments.length) {
      mydata.template_doc_ids = [];
      let x;
      for (x = 0; x < this.state.templateDocuments.length; x += 1) {
        mydata.template_doc_ids.push(this.state.templateDocuments[x].id);
      }
    }
    if (this.state.templateDocuments.length) {
      this.setState({ loading: true, draftStatus: 'Saving' });
      const request = Axios.post(CREATE_DOC_FROM_TEMPLATE_IDS, mydata);
      request
        .then((response) => {
          this.setState({ loading: false });
          if (response.data.status === 200) {
            const documents = [...this.state.documents];
            const doc = documents.length
              ? documents
              : [...this.state.templateDocuments];
            if (doc.length) {
              this.setState(
                { documents: response.data.documents, templateDocuments: [] },
                () => {
                  clearTimeout(this.timer);
                  this.timer = setTimeout(() => {
                    this.saveMessage('Draft');
                  }, 1000);
                },
              );
              // let x;
              // for (x = 0; x < doc.length; x += 1) {
              //   const documnt = { ...doc[x] };
              //   documnt.id = response.data.doc_ids[x];
              //   doc[x] = documnt;
              //   this.setState({ documents: doc });
              //   if (x + 1 === doc.length) {
              //     this.setState({ templateDocuments: [] });
              //     clearTimeout(this.timer);
              //     this.timer = setTimeout(() => {
              //       this.saveMessage('Draft');
              //     }, 1000);
              //   }
              // }
            }
          } else {
            AlertMessage('error', response.data.message, 1500);
          }
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    }
  };

  inputRenderer = (inputProps) => (
    <components.Input
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...inputProps}
      value={this.state.titleChange}
      className="Select-input"
    />
  );

  templateDropdown(selectedOption) {
    const { subject, documents, comment, warningMsgLost } = this.state;
    const Comment = this.validateDraftMessage(comment);
    if (
      (subject || Comment || documents.length) &&
      !warningMsgLost &&
      (!selectedOption || (selectedOption && !subject))
    ) {
      this.setState({ warningMsgLost: true, selectedTemplate: selectedOption });
    } else if (selectedOption && selectedOption !== null) {
      // this.discardDraft();
      // const msgId = encodeURIComponent(this.state.messageId);
      // MessageApi.discardDraft(this, msgId, '', '', false, true);
      if (this.state.messageId) {
        this.deleteMsg(this.state.messageId, selectedOption);
      } else {
        const { content, value } = selectedOption;
        const coment = content;
        this.setState(
          {
            selectedOption,
            subject: value,
            titleChange: '',
            comment: coment,
            uniqueId: generateUniqueHash(5),
            warningMsgLost: false,
            selectedTemplate: null,
            templateDocuments:
              selectedOption.documents && selectedOption.documents.length
                ? selectedOption.documents
                : [],
            documents:
              selectedOption.documents && selectedOption.documents.length
                ? selectedOption.documents
                : [],
          },
          () => {
            // console.log('selectedOption', selectedOption, this.state.templateDocuments);
            // if (selectedOption.documents && selectedOption.documents.length) {
            //   // console.log('1');
            //   this.getTemplateDocsId();
            // } else {
            //   console.log('2');
            //   clearTimeout(this.timer);
            //   this.timer = setTimeout(() => { this.saveMessage('Draft'); }, 500);
            //   // this.saveMessage('Draft');
            // }
          },
        );
      }
    } else {
      // const { templateDocuments } = this.state;
      // const docs = this.state.documents;
      // if (docs && docs.length && templateDocuments && templateDocuments.length) {
      //   const documnts = [];
      //   docs.map((doc) => {
      //     if (doc.id && !_.find(templateDocuments, { id: doc.id })) {
      //       documnts.push(doc);
      //     }
      //     return documnts;
      //   });
      //   this.setState({ documents: documnts, templateDocuments: [] });
      // }
      this.setState(
        {
          selectedOption: null,
          subject: '',
          titleChange: '',
          comment: '',
          uniqueId: generateUniqueHash(5),
          warningMsgLost: false,
          selectedTemplate: null,
          documents: [],
        },
        () => {
          const node = this.selectInputRef.current;
          if (node) {
            node.state.inputValue = '';
          }
          clearTimeout(this.timer);
          this.timer = setTimeout(() => {
            this.saveMessage('Draft');
          }, 1000);
          // this.saveMessage('Draft');
        },
      );
    }
  }

  deleteMsg = (messageId, selectedOption) => {
    const msgId = encodeURIComponent(messageId);
    this.setState({ loading: true, apiHitting: true });
    const request = Axios.delete(`${DISCARD_DRAFT}/${msgId}`);
    request.then((response) => {
      if (response.status === 200) {
        const { content, value } = selectedOption;
        const coment = content;
        this.setState(
          {
            selectedOption,
            subject: value,
            titleChange: '',
            comment: coment,
            uniqueId: generateUniqueHash(5),
            warningMsgLost: false,
            selectedTemplate: null,
            contactId: '',
            contactName: '',
            accountId: '',
            accountName: '',
            uploadFiles: [],
            inputVal: '',
            messageId: '',
            messageThreadId: '',
            templateDocuments:
              selectedOption.documents && selectedOption.documents.length
                ? selectedOption.documents
                : [],
            documents:
              selectedOption.documents && selectedOption.documents.length
                ? selectedOption.documents
                : [],
          },
          () => {
            this.setState({ loading: false, apiHitting: false });
            if (
              this.state.templateDocuments &&
              !this.state.templateDocuments.length
            ) {
              clearTimeout(this.timer);
              this.timer = setTimeout(() => {
                this.saveMessage('Draft');
              }, 1000);
            }
          },
        );
      } else {
        this.setState({
          apiHitting: false,
          loading: false,
        });
      }
    });
  };

  templateChange = (input, action) => {
    const val =
      action.action === 'input-change' ? input : action.prevInputValue;
    if (val || (!val && this.state.titleChange)) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.saveMessage('Draft');
      }, 500);
      this.setState({
        subject: val,
        titleChange: val,
      });
    }
  };

  handleChange(e) {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.saveMessage('Draft');
    }, 500);
    this.setState({ [e.target.name]: e.target.value });
  }

  removeSelectedRecord(contactId, accountId) {
    const selectedRecordsArr = this.state.allSelectedRecords;
    const index = selectedRecordsArr.findIndex(
      (x) => x.contactId === contactId && x.accountId === accountId,
    );
    if (index > -1) {
      selectedRecordsArr.splice(index, 1);
      this.setState({ allSelectedRecords: selectedRecordsArr });
    }
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.saveMessage('Draft');
    }, 500);
  }

  handleCommentChange(value) {
    this.setState({ comment: value, errors: {} });
    if (this.state.templateDocuments && this.state.templateDocuments.length) {
      this.getTemplateDocsId();
    } else {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.saveMessage('Draft');
      }, 1000);
    }
    // clearTimeout(this.timer);
    // this.timer = setTimeout(() => { this.saveMessage('Draft'); }, 1000);
  }

  handleCheckboxChange = (inputvalue, fieldname) => {
    const inputfieldvalue =
      inputvalue && inputvalue.target && inputvalue.target.value
        ? inputvalue.target.value
        : undefined;
    const value = !(inputfieldvalue === 'true');
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.saveMessage('Draft');
    }, 500);
    this.setState({ [fieldname]: value });
  };

  handleImageUploadStatus = (status) => {
    this.setState({ uploadingImages: status }, () => {
      if (
        !this.state.uploadingImages &&
        this.state.sendMessageAfterImageUpload
      ) {
        this.saveMessage('Sent');
      } else {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.saveMessage('Draft');
        }, 1000);
      }
    });
  };

  closeModal() {
    const { navigate } = this.props;
    const { state } = this.props.location;
    if (this.state.draftEdit) {
      navigate('/redirect');
      setTimeout(() => {
        navigate('/all_messages', {
          state: {
            from: '/draft/:id',
            filter: 'draft',
            activeTab: isUserEmployee() ? 3 : 2,
          },
        });
      }, 100);
    } else if (state && state.fro && state.fro === 'inboxlist') {
      navigate('/all_messages', {
        state: { filter: state.filter, activeTab: state.activeTab },
      });
    } else {
      this.props.navigate(-1);
    }
  }

  handleContactChange(val) {
    this.setState({ isUserTyping: false });
    if (!val && !this.state.accountId) {
      this.getContAcc();
    } else if (!val) {
      this.setState({
        contactId: '',
        contactName: '',
        contatIdPopup: '',
        assigneeType: '',
        recipientType: '',
      });
    } else if (val.type === 'contact' && !val.hasOwnProperty('accounts')) {
      // if contact has no account associated with it
      this.setState({
        accounts: [],
        contactId: val.value,
        contatIdPopup: val.id,
        contactName: val.label,
        accountId: '',
        accountName: '',
        cpaId: val.cpa_id,
        assigneeType: val.assigne_type,
        recipientType: val.type,
        selectedContact: val,
      });
    } else if (val.type === 'emp' && !this.state.accountId) {
      this.getContAcc('accounts'); // reset accounts data
      this.setState({
        contactId: val.value,
        contactName: val.label,
        selectedContact: val,
        cpaId: val.cpa_id,
        assigneeType: val.assigne_type,
        recipientType: val.type,
      });
    } else if (val.type === 'emp' && this.state.accountId) {
      this.setState({
        contactId: val.value,
        contactName: val.label,
        selectedContact: val,
        cpaId: val.cpa_id,
        assigneeType: val.assigne_type,
        recipientType: val.type,
      });
    } else if (val.accounts.length === 1) {
      // autopopulate account if only one account
      this.setState(
        {
          accountId: val.accounts[0].value,
          accountName: val.accounts[0].label,
          accounts: val.accounts,
          contactId: val.value,
          contatIdPopup: val.id,
          contactName: val.label,
          cpaId: val.cpa_id,
          assigneeType: val.assigne_type,
          recipientType: val.type,
          selectedContact: val,
        },
        () => {
          this.setState({ isLoading: true });
          TaskApi.getContEmpOfAccount(this, {
            accountId: val.accounts[0].value,
            accountName: val.accounts[0].label,
          });
        },
      );
    } else {
      const eleArray = this.state.accounts.map((item) => item.value);
      if (eleArray.indexOf(this.state.accountId) > -1) {
        this.setState({
          accounts: val.accounts,
          contactId: val.value,
          contatIdPopup: val.id,
          contactName: val.label,
          selectedContact: val,
          cpaId: val.cpa_id,
          assigneeType: val.assigne_type,
          recipientType: val.type,
        });
      } else {
        // set the accounts related to that contact
        this.setState({
          accounts: val.accounts,
          contactId: val.value,
          contatIdPopup: val.id,
          contactName: val.label,
          selectedContact: val,
          cpaId: val.cpa_id,
          assigneeType: val.assigne_type,
          recipientType: val.type,
        });
      }
    }
  }

  handleContactInput(val) {
    if (!this.state.accountId && val) {
      if (val.length < 2) {
        this.getContAcc('contacts');
      } else {
        this.setState({ contacts: [] });
        clearTimeout(this.contactSearchTime);
        this.contactSearchTime = setTimeout(() => {
          const request = Axios.get(
            `${SEARCH_TASK_CONTACTS}/?keyword=${val}&message=true`,
          );
          request.then((response) => {
            if (response.status === 200) {
              this.setState({ contacts: response.data }, () =>
                this.groupRecepients(),
              );
            }
          });
        }, 500);
      }
    }
  }

  handleAccountInput(val) {
    // TODO: __HOMEDATA-AUDIT
    const homeDataObj = getStorageData();
    const { contactId, recipientType } = this.state;
    if (
      val.length >= 2 &&
      homeDataObj.is_employee &&
      ((contactId && recipientType === 'emp') || !contactId)
    ) {
      this.setState({ accounts: [] });
      clearTimeout(this.accountSearchTime);
      this.accountSearchTime = setTimeout(() => {
        const request = Axios.get(
          `${SEARCH_TASK_ACCOUNTS}/?keyword=${val}&message=true`,
        );
        request.then((response) => {
          if (response.status === 200) {
            this.setState({ accounts: response.data });
          }
        });
      }, 500);
    }
    if (this.state.errors.accont) {
      const err = {};
      err.accont = '';
      this.setState({ errors: err });
    }
  }

  handleAccountChange(val, typeOfAction) {
    const { action, removedValues } = typeOfAction;
    if (action === 'clear' && removedValues.length === 0) {
      return;
    }
    // TODO: __HOMEDATA-AUDIT
    const homeDataObj = getStorageData();

    this.setState({ isUserTyping: false });

    if (!val && !this.state.contactId) {
      this.getContAcc();
    } else if (!val) {
      // reset account select when there is no value
      if (this.state.recipientType === 'emp') {
        this.setState({
          accounts: this.state.allAccounts,
          accountId: null,
          accountName: null,
          groupedOptions: groupEmpAndContacts(this.state.allContacts),
        });
      } else {
        this.setState({
          accounts: this.state.accounts,
          accountId: null,
          accountName: null,
        });
      }
    } else {
      this.setState({
        accountId: val.value,
        accountName: val.label,
      });
      const mydata = {
        accountId: val.value,
        accountName: val.label,
      };
      if (homeDataObj && homeDataObj.is_employee) {
        this.setState({ isLoading: true });
        TaskApi.getContEmpOfAccount(this, mydata);
      } else {
        TaskApi.getRelConAndCstOfAccounts(this, val.value);
      }
    }
    if (this.state.errors.accont) {
      const err = {};
      err.accont = '';
      this.setState({ errors: err });
    }
  }

  // eslint-disable-next-line consistent-return
  addSelectedInput(isInitialLoad) {
    if (!this.state.contactId) return false;
    let selectedInput = {
      contactId: this.state.contactId,
      accountId: this.state.accountId,
      contactName: this.state.contactName,
      accountName: this.state.accountName,
      assigneeType: this.state.assigneeType,
    };
    if (
      this.state.contactDetails &&
      this.state.contactDetails.accounts &&
      this.state.contactDetails.accounts.length &&
      this.state.contactDetails.accounts.length === 1 &&
      // only hydrate the account (and other details) if this is coming from the initial load
      // this prevents accounts from accidentally being attached to messages.
      isInitialLoad === true
    ) {
      selectedInput = {
        contactId: this.state.contactId,
        accountId: this.state.contactDetails.accounts[0].value,
        contactName: this.state.contactName,
        accountName: this.state.contactDetails.accounts[0].label,
        assigneeType: this.state.assigneeType,
      };
    }
    const selectedRecordsArr = this.state.allSelectedRecords;
    const index = selectedRecordsArr.findIndex(
      (x) =>
        x.contactId === selectedInput.contactId &&
        parseInt(x.accountId, 10) === selectedInput.accountId,
    );
    if (index === -1) {
      selectedRecordsArr.push(selectedInput);
      this.setState({
        allSelectedRecords: selectedRecordsArr,
        dropdownOpen: false,
        contacts: this.state.allContacts,
        accounts: this.state.allAccounts,
        groupedOptions: groupEmpAndContacts(this.state.allContacts),
        contactId: '',
        contatIdPopup: '',
        accountId: '',
        contactName: '',
        accountName: '',
        assigneeType: '',
        selectedContact: '',
      });
    } else {
      this.setState({
        dropdownOpen: false,
        contacts: this.state.allContacts,
        accounts: this.state.allAccounts,
        groupedOptions: groupEmpAndContacts(this.state.allContacts),
        contactId: '',
        contatIdPopup: '',
        accountId: '',
        contactName: '',
        accountName: '',
        assigneeType: '',
        selectedContact: '',
        contactDetails: { accounts: [] },
      });
    }

    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.saveMessage('Draft');
    }, 500);
  }

  handleValidation(comment) {
    const err = {};
    let formIsValid = true;
    if (!comment) {
      formIsValid = false;
      err.comment = 'Enter Description';
    }
    const selectedRecordsArr = this.state.allSelectedRecords;

    if (selectedRecordsArr.length < 1) {
      formIsValid = false;
      err.recipient = 'Please select a recipient';
    }

    this.setState({ errors: err });
    return formIsValid;
  }

  validateDraftMessage(comment) {
    let output = comment ? comment.toString() : '';
    let haveImages = false;
    if (comment && comment.entityMap && Object.keys(comment.entityMap).length) {
      haveImages = true;
    }
    if (comment && typeof comment === 'object') {
      const content = EditorState.createWithContent(
        convertFromRaw(comment),
      ).getCurrentContent();
      const isEditorEmpty = !content.hasText();
      const currentPlainText = content.getPlainText();
      const lengthOfEditorContent = currentPlainText.length;
      const lengthOfTrimmedContent = currentPlainText.trim().length;
      const isContainOnlySpaces = !isEditorEmpty && !lengthOfTrimmedContent;
      if ((lengthOfEditorContent && !isContainOnlySpaces) || haveImages) {
        output = removeBreakElementinList(
          stateToHTML(
            EditorState.createWithContent(
              convertFromRaw(comment),
            ).getCurrentContent(),
            options,
          ),
        );
      } else if (isContainOnlySpaces) {
        output = '';
      }
    }

    if (typeof output === 'object' || output === '[object Object]') {
      // for empty object
      output = '';
    }
    if (output && output.length > 0) {
      // Comment = Comment.replace(/\n/g, ' <br> ');
    }
    return output;
  }

  saveMessage(newStatus) {
    if (this.state.apiHitting && newStatus !== 'Sent') {
      return false;
    }
    const { subject, notifyViaText, comment } = this.state;
    const draftState = comment;
    clearTimeout(this.timer);
    const Comment = this.validateDraftMessage(draftState);
    if (newStatus === 'Sent') {
      const validate = this.handleValidation(Comment);
      if (!validate) {
        return false;
      }
      if (this.state.uploadingImages) {
        this.setState({
          loading: true,
          sendMessageAfterImageUpload: true,
        });
        return false;
      }
    } else if (this.state.uploadingImages) {
      return false;
    }
    this.setState({ apiHitting: true });
    const selectedRecordsArr = this.state.allSelectedRecords;
    if (
      Comment.trim() === '' &&
      selectedRecordsArr.length < 1 &&
      this.state.documents.length < 1 &&
      (!subject || (subject && subject.trim() === '')) &&
      !notifyViaText
    ) {
      console.log('empty draft');
      if (!this.state.messageId) {
        this.setState({ apiHitting: false });
        return false;
      }
      // return false;
    }
    const participantsArr = [];
    selectedRecordsArr.forEach((item) => {
      const tmpHash = {
        id: item.contactId,
        account_id: item.accountId,
        is_recipient: true,
        type: item.assigneeType,
      };
      participantsArr.push(tmpHash);
    });

    const mydata = {
      thread: {
        title: this.state.subject,
        is_notify_via_text: this.state.notifyViaText,
        status: newStatus,
      },
      messages: {
        status: newStatus,
        message_thread_id: this.state.messageThreadId,
        content: Comment,
      },
      id: this.state.messageId,
      participants: participantsArr,
    };

    if (this.state.documents.length) {
      mydata.doc_ids = [];
      let x;
      for (x = 0; x < this.state.documents.length; x += 1) {
        mydata.doc_ids.push(this.state.documents[x].id);
      }
    }

    if (newStatus === 'Draft') {
      this.setState({ draftStatus: 'Saving' });
    }
    if (newStatus === 'Sent') {
      this.setState({ loading: true });
    }
    const request = Axios.post(MESSAGES_API_PATH, mydata);
    request
      .then((response) => {
        this.setState({ loading: false });

        trackMixpanelEvent(MIXPANEL_MESSAGE_EVENT, {
          state: response.data.status === 200 ? newStatus : 'error',
          from_email: false,
        });

        if (newStatus === 'Draft') {
          if (response.data.status === 422 && response.data.message === '') {
            this.setState({
              messageId: '',
              messageThreadId: '',
              apiHitting: false,
              draftStatus: '',
            });
          } else if (response.data.status === 400 && response.data.message) {
            AlertMessage('error', response.data.message, 1500);
            this.setState({
              apiHitting: false,
              draftStatus: '',
            });
          } else {
            this.setState(
              {
                messageThreadId: response.data.message_thread_id,
                messageId: response.data.message_id,
                draftStatus: 'Saved',
              },
              () => {
                this.setState({ apiHitting: false });
              },
            );
            setTimeout(() => {
              this.setState({ draftStatus: '' });
            }, 100);
          }
        } else {
          if (response.data.status === 200) {
            if (
              response.data.messages &&
              response.data.messages !== undefined &&
              response.data.messages.includes('away')
            ) {
              AlertMessage('success', response.data.messages, 3000);
            }
            this.closeModal();
          } else {
            AlertMessage('error', response.data.message, 1500);
          }
          this.setState({ apiHitting: false, draftStatus: '' });
        }
      })
      .catch(() => {
        this.setState({ apiHitting: false, loading: false, draftStatus: '' });
      });

    return false;
  }

  discardDraft() {
    if (this.state.messageId) {
      const msgId = encodeURIComponent(this.state.messageId);
      MessageApi.discardDraft(this, msgId, this.props.discardDraft, 'newMsg');
    }
    this.closeModal();
  }

  keyPress(e) {
    if (e && Number(e.keyCode) === 13) {
      e.preventDefault();
    }
  }

  errorDoc = (field = '') => {
    if (field === 'account') {
      const err = {};
      err.accont = 'Please select account';
      err.documents = 'Please select document';
      this.setState({ errors: err });
    } else {
      const err = {};
      err.documents = 'Please select document';
      this.setState({ errors: err });
    }
  };

  updateDocumentState = (documents) => {
    this.setState({ documents }, () => {
      this.saveMessage('Draft');
    });
  };

  renderTags() {
    const { allSelectedRecords } = this.state;
    if (!allSelectedRecords && allSelectedRecords.length < 1) {
      return null;
    }
    const selectedRecords = allSelectedRecords.map((item) => {
      if (item.accountId) {
        return (
          <span
            className="tag tag--fill receipt-account-tag"
            key={item.contactId + item.accountId}
          >
            {item.contactName} <i className="divOption" /> {item.accountName}{' '}
            <i
              className="icon-close2 remove"
              onClick={() =>
                this.removeSelectedRecord(item.contactId, item.accountId)
              }
            />
          </span>
        );
      }
      return (
        <span className="tag tag--fill" key={item.contactId + item.accountId}>
          {item.contactName}{' '}
          <i
            className="icon-close2 remove"
            onClick={() =>
              this.removeSelectedRecord(item.contactId, item.accountId)
            }
          />
        </span>
      );
    });
    return selectedRecords;
  }

  /** *********************** NEW CODE ********************* */
  autopopulateToField = (toFieldValue, accountData) => {
    const { value, label, assigne_type } = toFieldValue;
    const valueToPopulate = [
      {
        contactId: value,
        contactName: label,
        accountName: accountData.accountName,
        accountId: accountData.accountId,
        assigne_type,
      },
    ];

    this.setState({ allSelectedRecords: valueToPopulate });
  };

  // get filtered results when performing a search in select
  getContactFilteredResults = async (searchTerm, from = '') => {
    try {
      const response = await Axios.get(
        `${SEARCH_TASK_CONTACTS}/?keyword=${searchTerm}&message=true`,
      );

      if (response.status === 200) {
        const groupedOptions = groupEmpAndContacts(response.data);
        if (from === 'addToRecepient') {
          const contactDetails = response.data[0];
          this.handleContactChange(contactDetails);
          this.addSelectedInput();
        } else {
          this.setState({
            contacts: response.data,
            groupedOptions,
          });
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  // get filtered results when performing a search in account select
  getAccountFilteredResults = async (searchTerm) => {
    try {
      const response = await Axios.get(
        `${SEARCH_TASK_ACCOUNTS}/?keyword=${searchTerm}&message=true`,
      );

      if (response.status === 200) {
        this.setState({ accounts: response.data, allAccounts: response.data });
      }
    } catch (err) {
      console.error(err);
    }
  };

  // handle contact select input change
  handleContactInputChange = debounce((inputValue, typeOfAction) => {
    const { action } = typeOfAction;
    const { accountId, isUserTyping } = this.state;

    /**
     * execute this block when the user clears the select input while hitting backspace or
     * closes the menu without selecting an option
     */
    if (action === 'menu-close' && isUserTyping) {
      this.getContAcc('contacts');
    }

    if (action === 'input-change' && !accountId) {
      if (inputValue) {
        this.getContactFilteredResults(inputValue);
        this.setState({ isUserTyping: true });
      } else {
        this.getContAcc('contacts');
      }
    }
  }, 300);

  // handle account select input change
  handleAccountInputChange = debounce((inputValue, typeOfAction) => {
    const { action, prevInputValue } = typeOfAction;
    const { isUserTyping, contactId, recipientType } = this.state;

    // TODO: __HOMEDATA-AUDIT
    const homeDataObj = getStorageData();

    /**
     * execute this block when the user clears the select input while hitting backspace or
     * closes the menu without selecting an option
     */
    if (
      (action === 'menu-close' || prevInputValue.length === 1) &&
      isUserTyping
    ) {
      this.setState((prevState) => ({
        accounts: prevState.allAccounts,
        accountId: '',
        accountName: '',
      }));
    }

    if (inputValue) {
      if (
        action === 'input-change' &&
        homeDataObj.is_employee &&
        ((contactId && recipientType === 'emp') || !contactId)
      ) {
        this.getAccountFilteredResults(inputValue);
        this.setState({ isUserTyping: true });
      } else if (Array.isArray(this.state.ref_accounts)) {
        this.setState({ allAccounts: this.state.ref_accounts });
      }
    }
  }, 300);

  // reset select dropdowns
  resetSelectDropdowns = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
      contacts: prevState.allContacts,
      accounts: prevState.allAccounts,
      groupedOptions: groupEmpAndContacts(prevState.allContacts),
      selectedContact: '',
      contactId: '',
      contatIdPopup: '',
      accountId: '',
      contactName: '',
      accountName: '',
      inputVal: '',
      errors: {},
    }));
  };

  render() {
    const {
      selectedOption,
      templateOptions,
      selectedTemplate,
      subject,
      groupedOptions,
      isLoading,
      modalDataLoading,
    } = this.state;
    // TODO: __HOMEDATA-AUDIT
    const homeDataObj = getStorageData();
    const initials =
      homeDataObj && homeDataObj.uname
        ? (homeDataObj.uname.split(' ')[0]
          ? homeDataObj.uname.split(' ')[0].charAt(0)
          : '') +
        (homeDataObj.uname.split(' ')[1]
          ? homeDataObj.uname.split(' ')[1].charAt(0)
          : '')
        : '';
    const noneStyle = { display: 'none' };
    const blockStyle = { display: 'block' };

    return (
      <div id="content" data-testid="newMessageModal">
        <div id="loading" style={this.state.loading ? blockStyle : noneStyle} />
        <div className="center-wrapper">
          <Modal
            modalClassName="msgModalIn"
            backdrop={false}
            className="customModal customModal--w990 customModal--nopadding"
            isOpen
            data-testid="new_message_modal"
          >
            <div className="ModalHeader">
              <button
                type="button"
                onClick={this.closeModal}
                className="close"
                aria-label="Close"
                data-testid="close_modal"
              >
                <i className="icon-close2" aria-hidden="true" />
              </button>
            </div>
            <ModalBody className="modalContent">
              <div className="modalContent--inner">
                <div className="modalContent--header">
                  <h5 className="modalContent--header__heading">New Message</h5>
                </div>

                {modalDataLoading && (
                  <div className="NewMessageModal__Loading">
                    <p>LOADING...</p>
                  </div>
                )}

                {!modalDataLoading && (
                  <div className="modalForm">
                    <Form>
                      <FormGroup
                        className={
                          this.state.errors.recipient ? 'form-error' : ''
                        }
                      >
                        <Label htmlFor="forEmail">To</Label>
                        {homeDataObj && homeDataObj.is_employee ? (
                          <div className="mb-2 float-right">
                            <CheckboxField
                              id="notifyViaText"
                              name="notifyViaText"
                              label="Notify Via Text"
                              labelclassName="text-body text-transform-none font-weight-normal"
                              value={this.state.notifyViaText}
                              onChange={this.handleCheckboxChange}
                              testid="message__notify_via_text"
                            />
                          </div>
                        ) : null}
                        <div className="labelValue customTags mailDrodown">
                          <div className="customTags__input form-control">
                            {this.renderTags()}
                            <a
                              href="javascript:;"
                              onClick={this.onFocus}
                              data-testid="onFocusButton"
                              className="inputHelper dropdown-toggle noCaret"
                            />
                          </div>
                          {this.state.dropdownOpen ? (
                            <DropdownMenu className="customTags__option option_box">
                              <RecipientAndAccountDropdowns
                                recipientOptions={groupedOptions}
                                isLoading={isLoading}
                                accountOptions={this.state.allAccounts}
                                handleContactChange={this.handleContactChange}
                                selectedContact={this.state.selectedContact}
                                handleAccountChange={this.handleAccountChange}
                                recipientValue={{
                                  label: this.state.contactName,
                                  value: this.state.contactId,
                                }}
                                accountValue={{
                                  label: this.state.accountName,
                                  value: this.state.accountId,
                                }}
                                handleContactInputChange={
                                  this.handleContactInputChange
                                }
                                handleAccountInputChange={
                                  this.handleAccountInputChange
                                }
                              />
                              {/* <OptionBoxSelectFieldWithAvatar
                              name="seachContact"
                              label="Recipient"
                              options={groupedOptions}
                              handleChange={this.handleContactChange}
                              handleInputChange={this.handleContactInput}
                              value={{
                                value: this.state.contactId,
                                label: this.state.contactName,
                              }}
                              placeholder="Select a Recipient"
                              autofocus
                              clearable
                            />
                            <OptionBoxSelectFieldWithAvatar
                              name="seachAccount"
                              label="Account"
                              options={this.state.accounts}
                              handleChange={this.handleAccountChange}
                              handleInputChange={this.handleAccountInput}
                              value={{
                                value: this.state.accountId,
                                label: this.state.accountName,
                              }}
                              placeholder="Select an account"
                              clearable
                            /> */}
                              <div className="btn-wrap btn--leftspace">
                                <Button
                                  className="btn-link btn-sm btn--onlyicon"
                                  onClick={this.resetSelectDropdowns}
                                  data-testid="recipients_cancel_button"
                                >
                                  Close
                                </Button>
                                <Button
                                  className="btn btn-sm btn-primary"
                                  onClick={this.addSelectedInput}
                                  data-testid="recipients_ok_button"
                                >
                                  Ok
                                </Button>
                              </div>
                            </DropdownMenu>
                          ) : null}
                        </div>
                        <span className="error_label">
                          {this.state.errors.recipient}
                        </span>
                      </FormGroup>
                      <FormGroup
                        className={
                          this.state.errors.comment
                            ? 'form-error cstmClr position-relative'
                            : 'cstmClr position-relative'
                        }
                      >
                        <Label htmlFor="subject">Subject</Label>
                        <div className="labelValue customEditor boxResize flWdth">
                          {(selectedOption && selectedOption !== null) ||
                            (!selectedOption &&
                              selectedOption === null &&
                              !homeDataObj.is_employee) ||
                            (!selectedOption &&
                              selectedOption === null &&
                              subject &&
                              !this.state.titleChange) ? (
                            <Input
                              autoComplete="off"
                              onKeyPress={(e) => this.keyPress(e)}
                              type="text"
                              name="subject"
                              id="forSubject"
                              value={this.state.subject}
                              onChange={this.handleChange}
                              placeholder=""
                            />
                          ) : (
                            <Select
                              name="subject"
                              placeholder=""
                              ref={this.selectInputRef}
                              className="select-custom-class slctTemp"
                              inputId="NewMessageModal__SelectTemplate"
                              value={selectedOption}
                              onChange={this.templateDropdown}
                              options={templateOptions}
                              inputValue={this.state.titleChange}
                              // optionComponent={SelectTemplateFieldOption}
                              onInputChange={this.templateChange}
                              // inputRenderer={(input) => this.inputRenderer(input)}
                              tabSelectsValue={false}
                              onBlurResetsInput={false}
                              onCloseResetsInput={false}
                              onSelectResetsInput={this.state.titleChange} // false shows
                              noResultsText=""
                              styles={selectStyles}
                              theme={selectTheme}
                              classNamePrefix="custom-react-select"
                              components={{
                                Option: SelectTemplateFieldOption,
                                Input: this.inputRenderer,
                              }}
                              id="message__subject"
                            />
                          )}
                        </div>
                        {this.state.subject && homeDataObj.is_employee ? (
                          <Button
                            className="btn btn-sm btn-primary"
                            onClick={() => this.templateDropdown(null)}
                          >
                            Clear
                          </Button>
                        ) : null}
                      </FormGroup>
                      <FormGroup
                        className={
                          this.state.errors.comment ? ' form-error' : ''
                        }
                      >
                        <Label htmlFor="forDescription">Description</Label>
                        <div className="labelValue customEditor boxResize">
                          <TinymceEditor
                            value={this.state.comment}
                            handleChange={this.handleCommentChange}
                          />
                          <span className="error_label">
                            {this.state.errors.comment}
                          </span>
                        </div>
                      </FormGroup>
                      <FormGroup className="dragWrap">
                        <Label htmlFor="attachments">Attachments</Label>
                        <TaskFileUpload
                          data={this.state}
                          uploadType="message"
                          messageComp="messageModal"
                          updateDocumentState={this.updateDocumentState}
                        />
                      </FormGroup>
                    </Form>
                  </div>
                )}
              </div>

              {!modalDataLoading && (
                <div className="modalFooter modal-footer">
                  <div className="modalFooter--inner justify-content-between">
                    <div className="senderInfo">
                      <Label htmlFor="senderInfo">Send As</Label>
                      <div
                        className={`avatar avatar--w20 ${homeDataObj && homeDataObj.is_employee
                            ? 'avatar--border avatar--borderPrimary '
                            : ''
                          }d-inline-block`}
                        data-testid="message__send_as"
                      >
                        {homeDataObj && homeDataObj.avatar ? (
                          <img
                            src={homeDataObj.avatar}
                            alt="Person"
                            width="20"
                            height="20"
                            className="rounded-circle"
                          />
                        ) : (
                          <span className="uName">
                            {initials.toUpperCase()}
                          </span>
                        )}
                        <span>{homeDataObj ? homeDataObj.uname : ''}</span>
                      </div>
                    </div>
                    <div className="btn-wrap btn--leftspace">
                      <span className="text-dark pr-4">
                        {this.state.draftStatus}
                      </span>
                      <TooltipItem
                        position="top"
                        tooltipType="button"
                        key="draftInModal"
                        text="Discard Message"
                        btnClass="btn-link btn--onlyicon"
                        clickAction={() => this.discardDraft()}
                        id="draftInModal"
                        iconn="icon-delete-small"
                      />
                      <Button
                        color="primary"
                        onClick={() => this.saveMessage('Sent')}
                        disabled={this.state.draftStatus === 'Saving'}
                        data-testid="message__send"
                      >
                        Send
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </ModalBody>
          </Modal>
          <ConfirmationModal
            isOpen={this.state.warningMsgLost}
            className="customModal customModal--w500 customModal--nopadding"
            messageText="You are about to delete the existing message. This action cannot be undone. Proceed?"
            noBtnClick={() => this.setState({ warningMsgLost: false })}
            yesBtnClick={() => this.templateDropdown(selectedTemplate)}
            yesBtnText="Yes"
            noBtnText="No"
          />
        </div>
      </div>
    );
  }
}

export default withRouter(connect(null, actions)(NewModalMessage));
