import cookie from 'js-cookie';
import { shutdown } from '@intercom/messenger-js-sdk';
import { getSiteCompanyName, getSiteHostname } from 'utilities/utils';
import axios from 'axios';
import {
  APP_SUBDOMAINS,
  FIRM_SITE_URL,
  HOME_API_PATH,
} from 'constants/constants';
import { websocket } from 'websocket';
import { AuthSession } from './auth.types';

/**
 * SESSION CONSTANTS
 * NOTE: These are universal within our Liscio app ecosystem
 * to enable cross-site authentication.
 */
const AUTH_SESSION = 'auth_session';
const AUTH_SESSION_CPA = 'auth_session_cpa';
const AUTH_LOGIN_REDIRECT = 'auth_login_redirect';

export const getAuthCookieDomain = () => {
  const hostRootAndTld = (window?.location?.hostname || '')
    .split('.')
    .slice(-2);
  if (hostRootAndTld.length === 2) {
    return hostRootAndTld.join('.');
  }
  return undefined;
};

const cookieConfig = {
  domain: getAuthCookieDomain(),
  secure: window.location?.protocol?.includes('https'),
};

/**
 * Generic, serialized session storage handlers
 */
export const getSessionValue = <T>(key: string) => {
  try {
    const value = cookie.get(key);
    if (value) {
      const parsedValue = JSON.parse(value) as T;
      return parsedValue;
    }
  } catch {
    // Do nothing
  }
  return undefined;
};
export const setSessionValue = <T>(key: string, value: T) => {
  const serializedValue = JSON.stringify(value);
  // Set to cookie
  cookie.set(key, serializedValue, cookieConfig);
};
export const removeSessionValue = (key: string) => {
  // Clear cookie
  cookie.remove(key, cookieConfig);
};

/**
 * AuthSession
 */
export const getAuthSession = () => {
  // Get auth session from new unviersal cookies
  const session = getSessionValue<AuthSession>(AUTH_SESSION);
  return session;
};
export const setAuthSession = (session: AuthSession) => {
  setSessionValue(AUTH_SESSION, session);
};
/**
 * @deprecated This is a deprecated version of setAuthSession that allows
 * specifying the root cookie domain when switching CPA sites in our legacy
 * auth model.
 */
export const setLegacyRootDomainAuthSession = (session: AuthSession) => {
  setSessionValue(AUTH_SESSION, session);
};
export const clearAuthSession = () => {
  if (window.Intercom) {
    shutdown();
  }
  removeSessionValue(AUTH_SESSION);
};

/**
 * Auth Session CPA
 */
export const getAuthSessionCpa = () =>
  getSessionValue<string>(AUTH_SESSION_CPA);
export const setAuthSessionCpa = (cpaUrl: string) =>
  setSessionValue(AUTH_SESSION_CPA, cpaUrl);
export const clearAuthSessionCpa = () => removeSessionValue(AUTH_SESSION_CPA);

/**
 * Auth login redirect
 */
export const getAuthLoginRedirect = () => cookie.get(AUTH_LOGIN_REDIRECT);
export const setAuthLoginRedirect = (loginRedirect: string) => {
  cookie.set(AUTH_LOGIN_REDIRECT, loginRedirect, cookieConfig);
};
export const clearAuthLoginRedirect = () => {
  cookie.remove(AUTH_LOGIN_REDIRECT, cookieConfig);
};

/**
 * Helper method to dispatch users to the appropriate
 * desitination site based on their auth session
 * TODO: If we stored the users site as part of the session
 * we wouldn't need to load home data on every request
 */
export const dispatchAuthUserRedirect = async (authTokenOverride?: string) => {
  const session = getAuthSession();
  const authToken = authTokenOverride || session?.auth_token;

  if (authToken) {
    // Load user home data and dispatch user based on variables
    const { data: homeData } = await axios.get(HOME_API_PATH, {
      headers: { Authorization: authToken },
    });

    const hostname = getSiteHostname();
    const subdomain = getSiteCompanyName();
    const isAppSite = APP_SUBDOMAINS.includes(subdomain);

    // Dispatch users based on firm/client role

    // Dispatch Firm users
    if (homeData.data.is_employee) {
      // Any firm user not on a firm site will be redirected to the firm site
      // NOTE: WE only redirect firm users from the app site
      if (isAppSite) {
        // TODO: Add path as necessary
        const firmSiteRedirectUrl = FIRM_SITE_URL;
        window.location.replace(firmSiteRedirectUrl);
      }
      return undefined;
    }

    // Dispatch Client Users
    const sessionSiteName = homeData.data.company_url;
    if (sessionSiteName && !sessionSiteName.includes(hostname)) {
      // Note: We might want to log a user out if they're not on the correct site.
      const clientSiteRedirectUrl = sessionSiteName;
      window.location.replace(clientSiteRedirectUrl);
    }
  }
  return undefined;
};

/**
 * Clear auth sesison, ws sesssion, and reload to login
 */
export const destroyCurrentSessionAndGoToLogin = () => {
  clearAuthSession();
  websocket.consumer.disconnect();
  localStorage.clear();
  window.location.href = '/';
  window.location.reload();
};
