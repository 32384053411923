import { useMutation, useQuery } from '@tanstack/react-query';
import {
  accountSearchByKeyword,
  getEmpListForAccount,
  updateTaxInChargeEmployee,
} from 'api/accounts';

export function useAccountSearchByKeyword(keyword: string) {
  return useQuery({
    queryKey: ['accountSearch', keyword],
    queryFn: () => accountSearchByKeyword(keyword),
    enabled: keyword.length > 0,
  });
}

export function useGetEmpListForAccount(id: number) {
  return useQuery({
    queryKey: ['getEmpListForAccount', id],
    queryFn: () => getEmpListForAccount(id),
    enabled: id > 0,
  });
}

export function useUpdateTaxInChargeEmployee() {
  return useMutation({
    mutationFn: updateTaxInChargeEmployee,
  });
}
