/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * API V5
 * OpenAPI spec version: v5
 */
import {
  useQuery
} from '@tanstack/react-query'
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  GetApiV5IntercomSettingsParams,
  IntercomSettings
} from '.././schemas'
import getApiV5IntercomSettingsMutator from '../../configOrvalAPIV5';
import type { ErrorType as GetApiV5IntercomSettingsErrorType } from '../../configOrvalAPIV5';



/**
 * @summary Get Intercom data
 */
export const getApiV5IntercomSettings = (
    params: GetApiV5IntercomSettingsParams,
 signal?: AbortSignal
) => {
      
      
      return getApiV5IntercomSettingsMutator<IntercomSettings>(
      {url: `/api/v5/intercom_settings`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetApiV5IntercomSettingsQueryKey = (params: GetApiV5IntercomSettingsParams,) => {
    return [`/api/v5/intercom_settings`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiV5IntercomSettingsQueryOptions = <TData = Awaited<ReturnType<typeof getApiV5IntercomSettings>>, TError = GetApiV5IntercomSettingsErrorType<void>>(params: GetApiV5IntercomSettingsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV5IntercomSettings>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV5IntercomSettingsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV5IntercomSettings>>> = ({ signal }) => getApiV5IntercomSettings(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV5IntercomSettings>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV5IntercomSettingsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV5IntercomSettings>>>
export type GetApiV5IntercomSettingsQueryError = GetApiV5IntercomSettingsErrorType<void>


/**
 * @summary Get Intercom data
 */

export function useGetApiV5IntercomSettings<TData = Awaited<ReturnType<typeof getApiV5IntercomSettings>>, TError = GetApiV5IntercomSettingsErrorType<void>>(
 params: GetApiV5IntercomSettingsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV5IntercomSettings>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV5IntercomSettingsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



