import { FileWithPath } from 'react-dropzone';

type FilesWithPathAndRpaType = FileWithPath & { rpa_type?: string };

export const getUploadedRpaFilesType = (
  files: FilesWithPathAndRpaType[],
): string => {
  const fileType = files?.[0]?.rpa_type || '';
  return fileType;
};
