/* eslint-disable no-console */
import { DirectUpload } from '@rails/activestorage';
import { FileWithPath } from 'react-dropzone';
import { useEffect, useMemo, useState } from 'react';
import { ACTIVESTORAGE_URL, MIXPANEL_IMPORT } from 'constants/constants';
import { getUploadedRpaFilesType } from 'utilities/getUploadedRpaFilesType/getUploadedRpaFilesType';
import { useMixpanel } from 'Mixpanel/Mixpanel';

const getFilenameFromError = (error: string) => {
  // regex that matches between the " and " characters in the error message
  // example:  'Error creating Blob for "dummy 2.pdf". Status: 0'
  const FILENAME_REGEX = /"(.*)"/;
  const match = error.match(FILENAME_REGEX);
  return match ? match[1] : '';
};

export interface FileError {
  error: boolean;
  filename: string;
}

export const useActiveStorageFileUploader = () => {
  const [files, setFiles] = useState<FileWithPath[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<
    (ActiveStorage.Blob | FileError)[]
  >([]);
  const [isUploading, setIsUploading] = useState(false);
  const mixpanel = useMixpanel();

  useEffect(() => {
    if (files.length > 0 && uploadedFiles.length === files.length) {
      // Mixpanel event for tracking the type of files uploaded
      const uploadedRpaFilesType = getUploadedRpaFilesType(files);
      mixpanel?.track(MIXPANEL_IMPORT, { upload_type: uploadedRpaFilesType });

      setIsUploading(false);
    }
  }, [uploadedFiles, files, mixpanel]);

  const finalizeUpload = (error: Error, blob: ActiveStorage.Blob) => {
    // add all blobs/errors to uploadedFiles to we can compare lengths
    // between files and uploadedFiles to determine if we're done uploading
    const parsedError: FileError = { error: true, filename: '' };
    if (error) {
      parsedError.filename = getFilenameFromError(error as unknown as string);
    }
    setUploadedFiles((uploadedFilesPrev) => [
      ...uploadedFilesPrev,
      blob || parsedError,
    ]);
  };

  const uploadFile = (file: FileWithPath) => {
    const uploader = new DirectUpload(file, ACTIVESTORAGE_URL);
    uploader.create(finalizeUpload);
  };

  const removeFile = (index: number) => {
    setFiles(files.filter((_, i) => i !== index));
  };

  const addFiles = (newFiles: FileWithPath[]) => {
    setFiles((filesPrev) => [...filesPrev, ...newFiles]);
  };

  const uploadFiles = async () => {
    setIsUploading(true);
    await Promise.allSettled(files.map((file) => uploadFile(file)));
  };

  const uploadSuccess = useMemo(() => {
    if (uploadedFiles.length === 0 || files.length === 0) return false;
    if (uploadedFiles.length === files.length) return true;
    return false;
  }, [files, uploadedFiles]);

  const uploadedCount = useMemo(
    () => ({
      success: uploadedFiles.filter((file: any) => !file?.error).length,
      error: uploadedFiles.filter((file: any) => file?.error).length,
    }),
    [uploadedFiles],
  );

  const uploadStatus = {
    success: uploadSuccess,
    successCount: uploadedCount.success,
    errorCount: uploadedCount.error,
  };

  return {
    files,
    addFiles,
    removeFile,
    uploadFiles,
    uploadedFiles,
    isUploading,
    uploadStatus,
  };
};
