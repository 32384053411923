/* eslint-disable no-console */
import { useState } from 'react';
import {
  Typography,
  FormControl,
  Box,
  FormLabel,
  Stack,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import {
  useGetEmpListForAccount,
  useUpdateTaxInChargeEmployee,
} from 'hooks/accounts-hooks';
import { toast } from 'react-toastify';

// Define the props type
type Props = {
  legacyAccountId: number;
  taxInChargeId: number;
};

/**
 * Component to designate a tax in-charge employee for an account
 */
export const TaxInCharge = ({ legacyAccountId, taxInChargeId }: Props) => {
  const [selectedEmployee, setSelectedEmployee] = useState(
    String(taxInChargeId) || '-1',
  );
  const { data: employeeList } = useGetEmpListForAccount(legacyAccountId);
  const { mutate: updateTaxInChargeEmployee, isLoading } =
    useUpdateTaxInChargeEmployee();

  // Handle the change event for the Select component
  const handleChange = (event: SelectChangeEvent) => {
    const employeeId = parseInt(event.target.value, 10);
    const newValue = event.target.value;

    updateTaxInChargeEmployee(
      {
        accountId: legacyAccountId,
        taxInChargeEmployeeId: employeeId,
      },
      {
        onSuccess: () => {
          setSelectedEmployee(newValue);
          toast.success('Tax In-Charge Employee updated successfully');
        },
        onError: (error: unknown) => {
          toast.error('Failed to update Tax In-Charge Employee');
          console.error(error);
        },
      },
    );
  };

  return (
    <Box sx={{ maxWidth: '36rem', p: 2 }}>
      <Typography variant="h2" gutterBottom>
        Designated Tax In-Charge
      </Typography>
      <Typography variant="body1" gutterBottom>
        The designated employee will receive all tax-related Alerts &
        Notifications for this Account, including an Alert when Liscio reminds
        this Account of impending Estimated Tax Payment due dates.
      </Typography>
      <FormControl>
        <Stack spacing={2} direction="row" alignItems="center">
          <FormLabel id="employee-label">
            <Typography variant="body1" fontWeight="bold">
              Employee:
            </Typography>
          </FormLabel>
          <Select
            labelId="employee-label"
            id="employee-select"
            value={selectedEmployee}
            onChange={handleChange}
            disabled={isLoading}
            sx={{ width: '20rem' }}
          >
            {employeeList && employeeList.length > 0 ? (
              employeeList.map((employee) => (
                <MenuItem key={employee.id} value={employee.id}>
                  {`${employee.first_name} ${employee.last_name}`}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="-1">
                <em>No employees available</em>
              </MenuItem>
            )}
          </Select>
        </Stack>
      </FormControl>
    </Box>
  );
};
