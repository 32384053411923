import { useRpaDocumentsQuery } from 'hooks/rpa-documents-processing-hooks';
import { RpaDocumentStatus } from 'gql/graphql';
import { Box, Skeleton, styled, Typography } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { useEffect } from 'react';

const Row = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

export type ProcessingRPAFilesProps = {
  processingCount?: number | null | undefined;
};

export const ProcessingRPAFiles = ({
  processingCount,
}: ProcessingRPAFilesProps) => {
  const hasElements =
    typeof processingCount === 'number' && processingCount > 0;
  const isFetchingEnabled = Boolean(processingCount && processingCount > 0);
  const {
    data,
    isLoading: isLoadingRpaDocuments,
    refetch,
  } = useRpaDocumentsQuery(
    {
      status: RpaDocumentStatus.Processing,
      perPage: 100,
      page: 1,
      sortDirection: 'desc',
    },
    {
      staleTime: 0,
      enabled: isFetchingEnabled,
    },
  );
  const isLoading =
    (isFetchingEnabled && isLoadingRpaDocuments) ||
    typeof processingCount !== 'number';

  useEffect(() => {
    if (processingCount && processingCount > 0) {
      refetch();
    }
  }, [processingCount, refetch]);

  return (
    <Box
      sx={{
        overflow: 'auto',
        flex: 1,
        paddingTop: 4,
        paddingLeft: 14,
        paddingRight: 14,
      }}
    >
      {isLoading && <Skeleton role="progressbar" />}
      {!isLoading && (
        <Row>
          <Typography variant="body2">Name</Typography>
          <Typography variant="body2">Uploaded</Typography>
        </Row>
      )}
      {hasElements &&
        !isLoading &&
        data?.rpaDocuments.collection.map((item) => (
          <Row key={item.id}>
            <Typography>{item.title}</Typography>
            <Typography>
              {format(parseISO(item.lastUpdated), "MM/dd/yyyy hh:mm aaaaa'm'")}
            </Typography>
          </Row>
        ))}
      {!isLoading && !hasElements && (
        <Row>
          <Typography sx={{ width: '100%', textAlign: 'center' }}>
            No imports currently processing.
          </Typography>
        </Row>
      )}
    </Box>
  );
};
