import SendRequest from 'components/Requests/components/SendRequest/SendRequest';
import { TaxDeliverySummary } from 'components/Requests/components/TaxDelivery/components/TaxDeliverySummary/TaxDeliverySummary';
import { WorkflowViewInfoBar } from 'components/Requests/components/WorkflowView/components/WorkflowViewInfoBar/WorkflowViewInfoBar';
import { WorkflowViewTitlePresentation } from 'components/Requests/components/WorkflowView/components/WorkflowViewTitleWrapper/WorkflowViewTitleWrapper';
import { WorkflowViewWrapper } from 'components/Requests/components/WorkflowView/components/WorkflowViewWrapper/WorkflowViewWrapper';
import { REQUESTS_PAGE_ROUTES } from 'components/Requests/requests.constants';
import { DASHBOARD_ROUTE } from 'constants/constants';
import { WorkflowRequestFragment } from 'gql/graphql';
import { useRequestExperience } from 'hooks/use-experiences-hook';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNavigate, useLocation } from 'react-router-dom-latest';
import { Header } from 'ui/Header/Header';

type Props = {
  workflowRequest?: WorkflowRequestFragment;
};

export const TaxDeliveryDispatchView = ({ workflowRequest }: Props) => {
  const { requestOptInBanners } = useFlags();
  const { isNewRequestExperienceEnabled } = useRequestExperience();

  const location = useLocation();
  const navigate = useNavigate();

  const handleGoBack = async () => {
    // remove once requestOptInBanners flag is no longer needed
    // the user has come from the new request page so location.key will be 'default'
    // but they should be taken back to the previous page (new requests) not the dashboard
    if (requestOptInBanners && (await isNewRequestExperienceEnabled())) {
      navigate(-1);
      return;
    }

    if (location.key !== 'default') {
      navigate(-1);
    } else {
      navigate(DASHBOARD_ROUTE);
    }
  };

  const handleClose = () => {
    navigate(REQUESTS_PAGE_ROUTES.requests);
  };

  return (
    <WorkflowViewWrapper
      slots={{
        Header: <Header onBack={handleGoBack} title="Review and Send" />,
        SideBar: (
          <SendRequest
            workflow={workflowRequest?.workflow}
            workflowRequest={workflowRequest}
            onClose={handleClose}
          />
        ),
      }}
    >
      <WorkflowViewTitlePresentation
        title={workflowRequest?.title || ''}
        description={workflowRequest?.workflow.description || ''}
        sourceDocuments={workflowRequest?.rpaDocuments || []}
        slots={{
          infoBar: <WorkflowViewInfoBar status={workflowRequest?.status} />,
        }}
      />
      <TaxDeliverySummary workflowRequest={workflowRequest} />
    </WorkflowViewWrapper>
  );
};
