import styled from '@emotion/styled';

export default styled.div`
  .MuiDrawer-paper {
    overflow-y: auto;
    background: #434566;
    color: #f9fafb;
    width: 200px;
    z-index: 2;

    .MuiSvgIcon-root {
      color: #f9fafb;
    }

    .Sidebar__MenuListItem {
      text-decoration: none;
      color: #f9fafb;
      display: inline-block;
      width: 100%;
      padding: 0;
    }

    .MuiListItemButton-root {
      padding: 7px 25px 7px 34px;

      .MuiListItemIcon-root {
        min-width: 40px;
        /* match olympus styles */
        font-size: 1rem;
        color: #fff;
      }

      .MuiTypography-root {
        font-size: 12px;
        font-weight: 600;
        font-family: inherit;
        letter-spacing: 1px;
      }
    }

    .MuiListItemButton-root:hover {
      color: #f9fafb;
      background: rgba(35, 36, 56, 0.4);
    }

    .Mui-selected,
    .Mui-focuseVisible {
      background-color: #232438 !important;
    }

    .StarMenuList {
      justify-content: center;
    }
  }

  .Sidebar__Logo {
    text-align: center;
    padding: 20px;

    img {
      width: 43px;
      cursor: pointer;
    }
  }

  .Sidebar__LogOut {
    text-align: center;
  }

  .AddNewMenuLinks {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #919eab !important;
  }

  .AddNewMenuContainer {
    background-color: #919eab !important;
  }

  .CenterNavButton {
    justify-content: center;
  }

  .ProfileSidebarText {
    color: #f9fafb;
  }

  .MuiBadge-badge {
    top: 4px;
    right: 23px;
    color: #fff;
    background-color: #00aecc;
    font-weight: 600;
    font-size: 0.75rem;
  }
  .Sidebar__MenuListItem .icon-Email:before {
    font-size: 1.1rem;
  }
`;

export const StyledExtraItems = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 6px 37px;
  font-size: small;
  text-align: center;
  > div[role='button'] {
    cursor: pointer;
  }
`;
