import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom-latest';
import { ToastContainer } from 'react-toastify';
import Modal from 'react-modal';

import CssBaseline from '@mui/material/CssBaseline';
import ThemeProvider from '@mui/material/styles/ThemeProvider';

import { REACT_ENVIRONMENT, SENTRY_DSN } from 'constants/constants';
// import App from './components/App';
import App from 'components/App_updated';
import configureStore from 'store/store';
// import { store as newStore, storePersistor } from 'store1';
import { setCookie } from 'utilities/utils';

import LightTheme from 'theme/light';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import { MobileSiteRedirect } from 'components/MobileSiteRedirect';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { WebsocketProvider } from 'websocket/WebsocketContext';
import { getAsyncLaunchDarklyProvider } from 'LaunchDarklyProvider';
import { MixpanelProvider } from 'Mixpanel/Mixpanel';
import { RequestsInterceptors } from 'components/RequestsInterceptors/RequestsInterceptors';
import { IntercomProvider } from 'Intercom/IntercomProvider';

// TODO: When we are ready open for Prod and/or Dev
if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    // Performance Monitoring
    // Capture 100% of the transactions, reduce in production!
    tracesSampleRate: REACT_ENVIRONMENT === 'prod' ? 0.1 : 1.0,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

    // Called before a breadcrumb is sent, optionally filters keys from breadcrumb get request urls
    beforeBreadcrumb: (breadcrumb) => {
      const awsLinkQueryParams = [
        'response-content-disposition',
        'Expires',
        'Signature',
        'Key-Pair-Id',
      ];

      const breadcrumbUrl = breadcrumb?.data?.url
        ? new URL(breadcrumb.data.url)
        : undefined;
      if (!breadcrumbUrl) {
        return breadcrumb;
      }

      const isAWSLink = awsLinkQueryParams.every((param) =>
        breadcrumbUrl.search.includes(param),
      );

      /* eslint-disable no-param-reassign */
      if (isAWSLink && breadcrumb?.data?.url) {
        breadcrumbUrl.search = '';
        breadcrumb.data.url = breadcrumbUrl.href;
      }

      return breadcrumb;
    },
  });
}

const locationHref = window.location.href;

Modal.setAppElement('#root');
if (locationHref.includes('document?token=')) {
  // The last of a dying breed nearing an impending extinction event...
  setCookie(
    locationHref,
    { path: '/', secure: true, expires: 1 },
    false,
    'redirect_url',
  );
}

const store = configureStore();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  },
});

// This prevents race conditions where components may (re)render before/after LaunchDarkly is ready.
// https://docs.launchdarkly.com/sdk/client-side/react/react-web#initializing-using-asyncwithldprovider
(async () => {
  const LaunchDarklyProvider = await getAsyncLaunchDarklyProvider();

  ReactDOM.render(
    <Provider store={store}>
      <WebsocketProvider>
        <QueryClientProvider client={queryClient}>
          <MixpanelProvider>
            <BrowserRouter>
              <ThemeProvider theme={LightTheme}>
                <LaunchDarklyProvider>
                  <IntercomProvider>
                    <MobileSiteRedirect>
                      <RequestsInterceptors />
                      <CssBaseline />
                      <ToastContainer />
                      <App />
                      <ReactQueryDevtools initialIsOpen={false} />
                    </MobileSiteRedirect>
                  </IntercomProvider>
                </LaunchDarklyProvider>
              </ThemeProvider>
            </BrowserRouter>
          </MixpanelProvider>
        </QueryClientProvider>
      </WebsocketProvider>
    </Provider>,
    document.getElementById('root'),
  );
})();
