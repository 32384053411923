/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * API V5
 * OpenAPI spec version: v5
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  ForbiddenErrorObject,
  GetApiV5FirmsRequestsParams,
  IndexRequestSchema,
  PostApiV5FirmsRequestsIdReopenBody,
  PostApiV5FirmsWorkflowRequestsBulkDispatch200,
  PostApiV5FirmsWorkflowRequestsBulkDispatchBody,
  PutApiV5FirmsRequestsIdBody,
  RequestSchema,
  RequestsBulkUpdateInputSchema,
  RequestsBulkUpdateOutputSchema,
  Uuid
} from '.././schemas'
import postApiV5FirmsWorkflowRequestsBulkDispatchMutator from '../../configOrvalAPIV5';
import type { ErrorType as PostApiV5FirmsWorkflowRequestsBulkDispatchErrorType } from '../../configOrvalAPIV5';
import postApiV5FirmsRequestsBulkUpdateMutator from '../../configOrvalAPIV5';
import type { ErrorType as PostApiV5FirmsRequestsBulkUpdateErrorType } from '../../configOrvalAPIV5';
import getApiV5FirmsRequestsMutator from '../../configOrvalAPIV5';
import type { ErrorType as GetApiV5FirmsRequestsErrorType } from '../../configOrvalAPIV5';
import putApiV5FirmsRequestsIdMutator from '../../configOrvalAPIV5';
import type { ErrorType as PutApiV5FirmsRequestsIdErrorType } from '../../configOrvalAPIV5';
import getApiV5FirmsRequestsIdMutator from '../../configOrvalAPIV5';
import type { ErrorType as GetApiV5FirmsRequestsIdErrorType } from '../../configOrvalAPIV5';
import deleteApiV5FirmsRequestsIdMutator from '../../configOrvalAPIV5';
import type { ErrorType as DeleteApiV5FirmsRequestsIdErrorType } from '../../configOrvalAPIV5';
import postApiV5FirmsRequestsIdRecallMutator from '../../configOrvalAPIV5';
import type { ErrorType as PostApiV5FirmsRequestsIdRecallErrorType } from '../../configOrvalAPIV5';
import postApiV5FirmsRequestsIdCloseMutator from '../../configOrvalAPIV5';
import type { ErrorType as PostApiV5FirmsRequestsIdCloseErrorType } from '../../configOrvalAPIV5';
import postApiV5FirmsRequestsIdArchiveMutator from '../../configOrvalAPIV5';
import type { ErrorType as PostApiV5FirmsRequestsIdArchiveErrorType } from '../../configOrvalAPIV5';
import postApiV5FirmsRequestsIdReopenMutator from '../../configOrvalAPIV5';
import type { ErrorType as PostApiV5FirmsRequestsIdReopenErrorType } from '../../configOrvalAPIV5';
import postApiV5FirmsRequestsIdZipMutator from '../../configOrvalAPIV5';
import type { ErrorType as PostApiV5FirmsRequestsIdZipErrorType } from '../../configOrvalAPIV5';



/**
 * @summary Dispatch one or many requests
 */
export const postApiV5FirmsWorkflowRequestsBulkDispatch = (
    postApiV5FirmsWorkflowRequestsBulkDispatchBody: PostApiV5FirmsWorkflowRequestsBulkDispatchBody,
 ) => {
      
      
      return postApiV5FirmsWorkflowRequestsBulkDispatchMutator<PostApiV5FirmsWorkflowRequestsBulkDispatch200>(
      {url: `/api/v5/firms/workflow_requests/bulk_dispatch`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiV5FirmsWorkflowRequestsBulkDispatchBody
    },
      );
    }
  


export const getPostApiV5FirmsWorkflowRequestsBulkDispatchMutationOptions = <TError = PostApiV5FirmsWorkflowRequestsBulkDispatchErrorType<ForbiddenErrorObject>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV5FirmsWorkflowRequestsBulkDispatch>>, TError,{data: PostApiV5FirmsWorkflowRequestsBulkDispatchBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiV5FirmsWorkflowRequestsBulkDispatch>>, TError,{data: PostApiV5FirmsWorkflowRequestsBulkDispatchBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV5FirmsWorkflowRequestsBulkDispatch>>, {data: PostApiV5FirmsWorkflowRequestsBulkDispatchBody}> = (props) => {
          const {data} = props ?? {};

          return  postApiV5FirmsWorkflowRequestsBulkDispatch(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV5FirmsWorkflowRequestsBulkDispatchMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV5FirmsWorkflowRequestsBulkDispatch>>>
    export type PostApiV5FirmsWorkflowRequestsBulkDispatchMutationBody = PostApiV5FirmsWorkflowRequestsBulkDispatchBody
    export type PostApiV5FirmsWorkflowRequestsBulkDispatchMutationError = PostApiV5FirmsWorkflowRequestsBulkDispatchErrorType<ForbiddenErrorObject>

    /**
 * @summary Dispatch one or many requests
 */
export const usePostApiV5FirmsWorkflowRequestsBulkDispatch = <TError = PostApiV5FirmsWorkflowRequestsBulkDispatchErrorType<ForbiddenErrorObject>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV5FirmsWorkflowRequestsBulkDispatch>>, TError,{data: PostApiV5FirmsWorkflowRequestsBulkDispatchBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV5FirmsWorkflowRequestsBulkDispatch>>,
        TError,
        {data: PostApiV5FirmsWorkflowRequestsBulkDispatchBody},
        TContext
      > => {

      const mutationOptions = getPostApiV5FirmsWorkflowRequestsBulkDispatchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Update Multiple Requests
 */
export const postApiV5FirmsRequestsBulkUpdate = (
    requestsBulkUpdateInputSchema: RequestsBulkUpdateInputSchema,
 ) => {
      
      
      return postApiV5FirmsRequestsBulkUpdateMutator<RequestsBulkUpdateOutputSchema>(
      {url: `/api/v5/firms/requests/bulk_update`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: requestsBulkUpdateInputSchema
    },
      );
    }
  


export const getPostApiV5FirmsRequestsBulkUpdateMutationOptions = <TError = PostApiV5FirmsRequestsBulkUpdateErrorType<ForbiddenErrorObject>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV5FirmsRequestsBulkUpdate>>, TError,{data: RequestsBulkUpdateInputSchema}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiV5FirmsRequestsBulkUpdate>>, TError,{data: RequestsBulkUpdateInputSchema}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV5FirmsRequestsBulkUpdate>>, {data: RequestsBulkUpdateInputSchema}> = (props) => {
          const {data} = props ?? {};

          return  postApiV5FirmsRequestsBulkUpdate(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV5FirmsRequestsBulkUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV5FirmsRequestsBulkUpdate>>>
    export type PostApiV5FirmsRequestsBulkUpdateMutationBody = RequestsBulkUpdateInputSchema
    export type PostApiV5FirmsRequestsBulkUpdateMutationError = PostApiV5FirmsRequestsBulkUpdateErrorType<ForbiddenErrorObject>

    /**
 * @summary Update Multiple Requests
 */
export const usePostApiV5FirmsRequestsBulkUpdate = <TError = PostApiV5FirmsRequestsBulkUpdateErrorType<ForbiddenErrorObject>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV5FirmsRequestsBulkUpdate>>, TError,{data: RequestsBulkUpdateInputSchema}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV5FirmsRequestsBulkUpdate>>,
        TError,
        {data: RequestsBulkUpdateInputSchema},
        TContext
      > => {

      const mutationOptions = getPostApiV5FirmsRequestsBulkUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Returns requests for the Cpa of the currently logged in user
 */
export const getApiV5FirmsRequests = (
    params?: GetApiV5FirmsRequestsParams,
 signal?: AbortSignal
) => {
      
      
      return getApiV5FirmsRequestsMutator<IndexRequestSchema>(
      {url: `/api/v5/firms/requests`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetApiV5FirmsRequestsQueryKey = (params?: GetApiV5FirmsRequestsParams,) => {
    return [`/api/v5/firms/requests`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiV5FirmsRequestsQueryOptions = <TData = Awaited<ReturnType<typeof getApiV5FirmsRequests>>, TError = GetApiV5FirmsRequestsErrorType<void | ForbiddenErrorObject>>(params?: GetApiV5FirmsRequestsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV5FirmsRequests>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV5FirmsRequestsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV5FirmsRequests>>> = ({ signal }) => getApiV5FirmsRequests(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV5FirmsRequests>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV5FirmsRequestsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV5FirmsRequests>>>
export type GetApiV5FirmsRequestsQueryError = GetApiV5FirmsRequestsErrorType<void | ForbiddenErrorObject>


/**
 * @summary Returns requests for the Cpa of the currently logged in user
 */

export function useGetApiV5FirmsRequests<TData = Awaited<ReturnType<typeof getApiV5FirmsRequests>>, TError = GetApiV5FirmsRequestsErrorType<void | ForbiddenErrorObject>>(
 params?: GetApiV5FirmsRequestsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV5FirmsRequests>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV5FirmsRequestsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary it updates a request
 */
export const putApiV5FirmsRequestsId = (
    id: Uuid,
    putApiV5FirmsRequestsIdBody: PutApiV5FirmsRequestsIdBody,
 ) => {
      
      
      return putApiV5FirmsRequestsIdMutator<RequestSchema>(
      {url: `/api/v5/firms/requests/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: putApiV5FirmsRequestsIdBody
    },
      );
    }
  


export const getPutApiV5FirmsRequestsIdMutationOptions = <TError = PutApiV5FirmsRequestsIdErrorType<ForbiddenErrorObject>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV5FirmsRequestsId>>, TError,{id: Uuid;data: PutApiV5FirmsRequestsIdBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof putApiV5FirmsRequestsId>>, TError,{id: Uuid;data: PutApiV5FirmsRequestsIdBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV5FirmsRequestsId>>, {id: Uuid;data: PutApiV5FirmsRequestsIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  putApiV5FirmsRequestsId(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutApiV5FirmsRequestsIdMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV5FirmsRequestsId>>>
    export type PutApiV5FirmsRequestsIdMutationBody = PutApiV5FirmsRequestsIdBody
    export type PutApiV5FirmsRequestsIdMutationError = PutApiV5FirmsRequestsIdErrorType<ForbiddenErrorObject>

    /**
 * @summary it updates a request
 */
export const usePutApiV5FirmsRequestsId = <TError = PutApiV5FirmsRequestsIdErrorType<ForbiddenErrorObject>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV5FirmsRequestsId>>, TError,{id: Uuid;data: PutApiV5FirmsRequestsIdBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof putApiV5FirmsRequestsId>>,
        TError,
        {id: Uuid;data: PutApiV5FirmsRequestsIdBody},
        TContext
      > => {

      const mutationOptions = getPutApiV5FirmsRequestsIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary it shows a request
 */
export const getApiV5FirmsRequestsId = (
    id: Uuid,
 signal?: AbortSignal
) => {
      
      
      return getApiV5FirmsRequestsIdMutator<RequestSchema>(
      {url: `/api/v5/firms/requests/${id}`, method: 'GET', signal
    },
      );
    }
  

export const getGetApiV5FirmsRequestsIdQueryKey = (id: Uuid,) => {
    return [`/api/v5/firms/requests/${id}`] as const;
    }

    
export const getGetApiV5FirmsRequestsIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiV5FirmsRequestsId>>, TError = GetApiV5FirmsRequestsIdErrorType<ForbiddenErrorObject>>(id: Uuid, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV5FirmsRequestsId>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV5FirmsRequestsIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV5FirmsRequestsId>>> = ({ signal }) => getApiV5FirmsRequestsId(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV5FirmsRequestsId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV5FirmsRequestsIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV5FirmsRequestsId>>>
export type GetApiV5FirmsRequestsIdQueryError = GetApiV5FirmsRequestsIdErrorType<ForbiddenErrorObject>


/**
 * @summary it shows a request
 */

export function useGetApiV5FirmsRequestsId<TData = Awaited<ReturnType<typeof getApiV5FirmsRequestsId>>, TError = GetApiV5FirmsRequestsIdErrorType<ForbiddenErrorObject>>(
 id: Uuid, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV5FirmsRequestsId>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV5FirmsRequestsIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary it deletes a request
 */
export const deleteApiV5FirmsRequestsId = (
    id: Uuid,
 ) => {
      
      
      return deleteApiV5FirmsRequestsIdMutator<void>(
      {url: `/api/v5/firms/requests/${id}`, method: 'DELETE'
    },
      );
    }
  


export const getDeleteApiV5FirmsRequestsIdMutationOptions = <TError = DeleteApiV5FirmsRequestsIdErrorType<ForbiddenErrorObject>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV5FirmsRequestsId>>, TError,{id: Uuid}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiV5FirmsRequestsId>>, TError,{id: Uuid}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiV5FirmsRequestsId>>, {id: Uuid}> = (props) => {
          const {id} = props ?? {};

          return  deleteApiV5FirmsRequestsId(id,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiV5FirmsRequestsIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiV5FirmsRequestsId>>>
    
    export type DeleteApiV5FirmsRequestsIdMutationError = DeleteApiV5FirmsRequestsIdErrorType<ForbiddenErrorObject>

    /**
 * @summary it deletes a request
 */
export const useDeleteApiV5FirmsRequestsId = <TError = DeleteApiV5FirmsRequestsIdErrorType<ForbiddenErrorObject>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV5FirmsRequestsId>>, TError,{id: Uuid}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiV5FirmsRequestsId>>,
        TError,
        {id: Uuid},
        TContext
      > => {

      const mutationOptions = getDeleteApiV5FirmsRequestsIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary it recalls a request
 */
export const postApiV5FirmsRequestsIdRecall = (
    id: Uuid,
 ) => {
      
      
      return postApiV5FirmsRequestsIdRecallMutator<RequestSchema>(
      {url: `/api/v5/firms/requests/${id}/recall`, method: 'POST'
    },
      );
    }
  


export const getPostApiV5FirmsRequestsIdRecallMutationOptions = <TError = PostApiV5FirmsRequestsIdRecallErrorType<ForbiddenErrorObject>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV5FirmsRequestsIdRecall>>, TError,{id: Uuid}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiV5FirmsRequestsIdRecall>>, TError,{id: Uuid}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV5FirmsRequestsIdRecall>>, {id: Uuid}> = (props) => {
          const {id} = props ?? {};

          return  postApiV5FirmsRequestsIdRecall(id,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV5FirmsRequestsIdRecallMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV5FirmsRequestsIdRecall>>>
    
    export type PostApiV5FirmsRequestsIdRecallMutationError = PostApiV5FirmsRequestsIdRecallErrorType<ForbiddenErrorObject>

    /**
 * @summary it recalls a request
 */
export const usePostApiV5FirmsRequestsIdRecall = <TError = PostApiV5FirmsRequestsIdRecallErrorType<ForbiddenErrorObject>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV5FirmsRequestsIdRecall>>, TError,{id: Uuid}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV5FirmsRequestsIdRecall>>,
        TError,
        {id: Uuid},
        TContext
      > => {

      const mutationOptions = getPostApiV5FirmsRequestsIdRecallMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary it closes a request
 */
export const postApiV5FirmsRequestsIdClose = (
    id: Uuid,
 ) => {
      
      
      return postApiV5FirmsRequestsIdCloseMutator<RequestSchema>(
      {url: `/api/v5/firms/requests/${id}/close`, method: 'POST'
    },
      );
    }
  


export const getPostApiV5FirmsRequestsIdCloseMutationOptions = <TError = PostApiV5FirmsRequestsIdCloseErrorType<ForbiddenErrorObject>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV5FirmsRequestsIdClose>>, TError,{id: Uuid}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiV5FirmsRequestsIdClose>>, TError,{id: Uuid}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV5FirmsRequestsIdClose>>, {id: Uuid}> = (props) => {
          const {id} = props ?? {};

          return  postApiV5FirmsRequestsIdClose(id,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV5FirmsRequestsIdCloseMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV5FirmsRequestsIdClose>>>
    
    export type PostApiV5FirmsRequestsIdCloseMutationError = PostApiV5FirmsRequestsIdCloseErrorType<ForbiddenErrorObject>

    /**
 * @summary it closes a request
 */
export const usePostApiV5FirmsRequestsIdClose = <TError = PostApiV5FirmsRequestsIdCloseErrorType<ForbiddenErrorObject>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV5FirmsRequestsIdClose>>, TError,{id: Uuid}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV5FirmsRequestsIdClose>>,
        TError,
        {id: Uuid},
        TContext
      > => {

      const mutationOptions = getPostApiV5FirmsRequestsIdCloseMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary it archives a request
 */
export const postApiV5FirmsRequestsIdArchive = (
    id: Uuid,
 ) => {
      
      
      return postApiV5FirmsRequestsIdArchiveMutator<RequestSchema>(
      {url: `/api/v5/firms/requests/${id}/archive`, method: 'POST'
    },
      );
    }
  


export const getPostApiV5FirmsRequestsIdArchiveMutationOptions = <TError = PostApiV5FirmsRequestsIdArchiveErrorType<ForbiddenErrorObject>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV5FirmsRequestsIdArchive>>, TError,{id: Uuid}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiV5FirmsRequestsIdArchive>>, TError,{id: Uuid}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV5FirmsRequestsIdArchive>>, {id: Uuid}> = (props) => {
          const {id} = props ?? {};

          return  postApiV5FirmsRequestsIdArchive(id,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV5FirmsRequestsIdArchiveMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV5FirmsRequestsIdArchive>>>
    
    export type PostApiV5FirmsRequestsIdArchiveMutationError = PostApiV5FirmsRequestsIdArchiveErrorType<ForbiddenErrorObject>

    /**
 * @summary it archives a request
 */
export const usePostApiV5FirmsRequestsIdArchive = <TError = PostApiV5FirmsRequestsIdArchiveErrorType<ForbiddenErrorObject>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV5FirmsRequestsIdArchive>>, TError,{id: Uuid}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV5FirmsRequestsIdArchive>>,
        TError,
        {id: Uuid},
        TContext
      > => {

      const mutationOptions = getPostApiV5FirmsRequestsIdArchiveMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary it reopens a request
 */
export const postApiV5FirmsRequestsIdReopen = (
    id: Uuid,
    postApiV5FirmsRequestsIdReopenBody: PostApiV5FirmsRequestsIdReopenBody,
 ) => {
      
      
      return postApiV5FirmsRequestsIdReopenMutator<RequestSchema>(
      {url: `/api/v5/firms/requests/${id}/reopen`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiV5FirmsRequestsIdReopenBody
    },
      );
    }
  


export const getPostApiV5FirmsRequestsIdReopenMutationOptions = <TError = PostApiV5FirmsRequestsIdReopenErrorType<ForbiddenErrorObject>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV5FirmsRequestsIdReopen>>, TError,{id: Uuid;data: PostApiV5FirmsRequestsIdReopenBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiV5FirmsRequestsIdReopen>>, TError,{id: Uuid;data: PostApiV5FirmsRequestsIdReopenBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV5FirmsRequestsIdReopen>>, {id: Uuid;data: PostApiV5FirmsRequestsIdReopenBody}> = (props) => {
          const {id,data} = props ?? {};

          return  postApiV5FirmsRequestsIdReopen(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV5FirmsRequestsIdReopenMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV5FirmsRequestsIdReopen>>>
    export type PostApiV5FirmsRequestsIdReopenMutationBody = PostApiV5FirmsRequestsIdReopenBody
    export type PostApiV5FirmsRequestsIdReopenMutationError = PostApiV5FirmsRequestsIdReopenErrorType<ForbiddenErrorObject>

    /**
 * @summary it reopens a request
 */
export const usePostApiV5FirmsRequestsIdReopen = <TError = PostApiV5FirmsRequestsIdReopenErrorType<ForbiddenErrorObject>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV5FirmsRequestsIdReopen>>, TError,{id: Uuid;data: PostApiV5FirmsRequestsIdReopenBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV5FirmsRequestsIdReopen>>,
        TError,
        {id: Uuid;data: PostApiV5FirmsRequestsIdReopenBody},
        TContext
      > => {

      const mutationOptions = getPostApiV5FirmsRequestsIdReopenMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary it starts zip creation process
 */
export const postApiV5FirmsRequestsIdZip = (
    id: Uuid,
 ) => {
      
      
      return postApiV5FirmsRequestsIdZipMutator<void>(
      {url: `/api/v5/firms/requests/${id}/zip`, method: 'POST'
    },
      );
    }
  


export const getPostApiV5FirmsRequestsIdZipMutationOptions = <TError = PostApiV5FirmsRequestsIdZipErrorType<ForbiddenErrorObject>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV5FirmsRequestsIdZip>>, TError,{id: Uuid}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiV5FirmsRequestsIdZip>>, TError,{id: Uuid}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV5FirmsRequestsIdZip>>, {id: Uuid}> = (props) => {
          const {id} = props ?? {};

          return  postApiV5FirmsRequestsIdZip(id,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV5FirmsRequestsIdZipMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV5FirmsRequestsIdZip>>>
    
    export type PostApiV5FirmsRequestsIdZipMutationError = PostApiV5FirmsRequestsIdZipErrorType<ForbiddenErrorObject>

    /**
 * @summary it starts zip creation process
 */
export const usePostApiV5FirmsRequestsIdZip = <TError = PostApiV5FirmsRequestsIdZipErrorType<ForbiddenErrorObject>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV5FirmsRequestsIdZip>>, TError,{id: Uuid}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV5FirmsRequestsIdZip>>,
        TError,
        {id: Uuid},
        TContext
      > => {

      const mutationOptions = getPostApiV5FirmsRequestsIdZipMutationOptions(options);

      return useMutation(mutationOptions);
    }
    