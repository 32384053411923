/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// TODO: Keep the comments for future use
import React, { useCallback } from 'react';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import { useIntercom } from 'Intercom/IntercomProvider';
import { MenuItemIcon } from './MenuItemIcon';

export default function HelpButton() {
  const { showIntercom, checkIfIntercomIsAvailable } = useIntercom();
  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();

      if (checkIfIntercomIsAvailable()) {
        showIntercom();
      }
    },
    [checkIfIntercomIsAvailable, showIntercom],
  );

  return (
    <div>
      <ListItemButton onClick={handleClick} data-testid="sidebar__help">
        <ListItemIcon>
          <MenuItemIcon iconClass="help" />
        </ListItemIcon>
        <ListItemText primary="NEED HELP?" />
      </ListItemButton>
    </div>
  );
}
