/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
// TODO: check react-s-alert
import React, { Component } from 'react';
import { Link } from 'react-router-dom-latest';
// import Alert from 'react-s-alert';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import * as actions from 'actions/TaskActions';
import * as messageActions from 'actions/MessageActions';
import * as PaginationActions from 'actions/PaginationActions';
import MessageContentLoader from 'common/MessageContentLoader';
import TaskContentLoader from 'common/TaskContentLoader';
import {
  getFirmType,
  isUserEmployee,
  getStorageData,
  getSiteHostname,
} from 'utilities/utils';
import List from 'components/Tasks/List';
import MessageList from 'components/Messages/Inbox/MessageList';
import './dashboard.scss';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { websocket } from 'websocket';
import { Box } from '@mui/material';
import { getAuthSession } from 'auth/session';
import { MyTodoList } from './components/MyTodoList/MyTodoList';
import { MyRequests } from './components/MyRequests/MyRequests';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sort: 'desc',
      field: 'updated_at',
      taskStatus: 'Review',
      messageTab: 'inbox',
      dataLoaded: false,
      taskLoading: false,
      homedata: {},
      mounted: true,
    };
    this.handleSorting = this.handleSorting.bind(this);
  }

  componentDidMount() {
    document.title = 'Dashboard';
    const { location } = this.props;

    const component = this;
    // TODO: __HOMEDATA-AUDIT
    const homeData = getStorageData();
    component.setState({ homedata: homeData });
    if (getAuthSession()) {
      component.props.messageActions.fetchMessages(component, location, 5, 1);
      if (getFirmType() !== 'liscio-lite') {
        component.props.actions.fetchDashboardTasks(
          component,
          1,
          component.state.sort,
          component.state.field,
        );
        component.tokenInterval = setInterval(() => {
          // TODO: __HOMEDATA-AUDIT
          const local_data = JSON.parse(
            localStorage.getItem(`${getSiteHostname().split(':')[0]} data`),
          );
          if (local_data) {
            clearInterval(component.tokenInterval);
            component.myTaskSubscription =
              websocket.consumer.subscriptions.create(
                {
                  channel: 'NewTaskChannel',
                  cpa_user_id: local_data.cpa_user_id,
                },
                {
                  connected: () => {
                    console.log('connected to newTaskChannel');
                  },
                  received: (data) => {
                    console.log('Data received - ', data);
                    component.props.actions.appendNewTask(data);
                  },
                  disconnected: () => {
                    console.log('disconnected from tasklist channel');
                  },
                },
              );
          }
        }, 200);
      }
    }
  }

  UNSAFE_componentWillReceiveProps() {
    // TODO: __HOMEDATA-AUDIT
    const homeData = getStorageData();
    this.setState({ homedata: homeData });
  }

  componentWillUnmount() {
    if (this.TaskCable && this.myTaskSubscription) {
      this.TaskCable.subscriptions.remove(this.myTaskSubscription);
    }
  }

  changeStatus(status, fro = '') {
    if (fro === 'message') {
      this.setState({ messageTab: status });
    } else {
      this.setState({ taskStatus: status });
    }
  }

  handleSorting(fieldName) {
    // eslint-disable-next-line react/no-access-state-in-setstate
    let stateSort = this.state.sort;
    if (this.state.field === fieldName || this.state.field === 'updated_at') {
      if (stateSort === 'asc') {
        stateSort = 'desc';
      } else {
        stateSort = 'asc';
      }
    } else {
      stateSort = 'desc';
    }

    this.setState({ sort: stateSort, field: fieldName, mounted: false }, () => {
      this.props.actions.fetchDashboardTasks(
        this,
        1,
        this.state.sort,
        this.state.field,
        '',
      );
    });
  }

  togglePinUnpin = (item, key) => {
    const msgArr = this.props.messages;
    const { messageTab } = this.state;
    msgArr[messageTab][key].is_pinned =
      !this.props.messages[messageTab][key].is_pinned;
    this.props.messageActions.fetchAllMessages(msgArr);
  };

  readMessageThread = (item, key) => {
    const msgArr = this.props.messages;
    const { messageTab } = this.state;
    msgArr[messageTab][key].read = true;
    this.props.messageActions.fetchAllMessages(msgArr);
  };

  navigateUrl = () => {
    const { navigate } = this.props;
    this.props.messageActions.handleMessageFilters({});
    this.props.PaginationActions.messagePagination(1);
    setTimeout(() => {
      navigate('/all_messages', {
        state: { query: { dontShowDefaultView: true } },
      });
    }, 50);
  };

  renderList() {
    const data = this.state.homedata;
    const { sort, field, mounted } = this.state;
    if (
      !data ||
      !this.props.tasks ||
      !this.props.tasks.templates ||
      this.state.taskLoading
    ) {
      return <TaskContentLoader />;
    }
    const prefTypes =
      data && data.pref_type_options
        ? data.pref_type_options.task_type_color
        : [];
    let tasksAll = null;
    // eslint-disable-next-line prefer-destructuring
    tasksAll = this.props.tasks.templates[0];
    tasksAll = data.is_employee
      ? tasksAll[this.state.taskStatus]
      : tasksAll.Open;
    if (mounted || (field === 'updated_at' && sort === 'desc')) {
      tasksAll = _.sortBy(
        tasksAll,
        (value) => new Date(value.updated_time),
      ).reverse();
    } else if (field === 'updated_at' && sort === 'asc') {
      tasksAll = _.sortBy(tasksAll, (value) => new Date(value.updated_time));
    }
    tasksAll = tasksAll.slice(0, isUserEmployee() ? 5 : 3);
    const totalrecords = tasksAll.length;
    return (
      <List
        tasks={tasksAll}
        handleSorting={this.handleSorting}
        data={this.state}
        pref={prefTypes}
        totalRecords={totalrecords}
        name="dashboard"
        className="Dashboard__List"
        navigate={this.props.navigate}
      />
    );
  }

  // Dashboard View for employee
  renderEmployeeView(count, alertCount) {
    const { messageTab, dataLoaded } = this.state;
    const {
      messages,
      flags: { newMessageChannelFeature },
    } = this.props;

    return (
      <section className="mb-5">
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <Link
              onClick={() => {
                const { location } = this.props;
                this.changeStatus('inbox', 'message')
                this.props.messageActions.fetchMessages(this, location, 5, 1, '', 'inbox');
              }}
              className={
                messageTab === 'inbox' ? 'nav-link active' : 'nav-link'
              }
              data-toggle="tab"
              to={(e) => e.preventDefault()}
              data-testid="dashboard__messages_tab"
            >
              {newMessageChannelFeature ? 'Liscio Messages' : 'Inbox'}{' '}
              {count ? `| ${count}` : ''}
            </Link>
          </li>

          {newMessageChannelFeature ? (
            <>
              <li className="nav-item">
                <Link
                  className={
                    messageTab === 'sms' ? 'nav-link active' : 'nav-link'
                  }
                  data-toggle="tab"
                  to="/sms"
                  data-testid="dashboard__sms_messages"
                >
                  SMS Messages
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={
                    messageTab === 'emails' ? 'nav-link active' : 'nav-link'
                  }
                  data-toggle="tab"
                  to="/emails"
                  data-testid="dashboard__client_emails"
                >
                  Client Emails
                </Link>
              </li>
            </>
          ) : null}

          <li className="nav-item">
            <Link
              onClick={() => {
                const { location } = this.props;
                this.changeStatus('alert', 'message')
                this.props.messageActions.fetchMessages(this, location, 5, 1, '', 'alert');
              }}
              className={
                messageTab === 'alert' ? 'nav-link active' : 'nav-link'
              }
              data-toggle="tab"
              to={(e) => e.preventDefault()}
              data-testid="dashboard__alerts"
            >
              Alerts {alertCount ? `| ${alertCount}` : ''}
            </Link>
          </li>
        </ul>
        <div className="tab-right-btn offset-1 position-relative pull-right text-right">
          <Link
            to="/message/new/modal"
            className="btn btn-primary"
            data-testid="dashboard__new_message"
          >
            <i>+</i> Message
          </Link>
        </div>
        {newMessageChannelFeature ? (
          ['sms', 'emails'].includes(messageTab) ? null : (
            <>
              <MessageList
                dataLoaded={dataLoaded}
                list={
                  messages && messages[messageTab] !== undefined
                    ? messages[messageTab].slice(0, 4)
                    : []
                }
                filter={messageTab}
                name="dashboard"
                togglePinUnpin={this.togglePinUnpin}
                readMessageThread={this.readMessageThread}
              />
              <div className="bottomSpacing text-right custom-page-link mb-4">
                <a
                  className="text-dark text-uppercase"
                  onClick={this.navigateUrl}
                  data-testid="dashboard__all_messages"
                >
                  See All Inbox Messages <i className="icon-right-arrow" />
                </a>
              </div>
            </>
          )
        ) : (
          <>
            <MessageList
              dataLoaded={dataLoaded}
              list={
                messages && messages[messageTab] !== undefined
                  ? messages[messageTab].slice(0, 4)
                  : []
              }
              filter={messageTab}
              name="dashboard"
              togglePinUnpin={this.togglePinUnpin}
              readMessageThread={this.readMessageThread}
            />
            <div className="bottomSpacing text-right custom-page-link mb-4">
              <a
                className="text-dark text-uppercase"
                onClick={this.navigateUrl}
                data-testid="dashboard__all_messages"
              >
                See All Inbox Messages <i className="icon-right-arrow" />
              </a>
            </div>
          </>
        )}
      </section>
    );
  }

  // Dashboard view for client
  renderClientView(count) {
    const { messageTab, dataLoaded } = this.state;
    const { messages } = this.props;
    return (
      <section>
        <div className="pb-4 section-title section-title--fullwidth section-title--bottomborder">
          <div className="col-container">
            <div className="col col-6">
              <h5>Inbox {count ? `| ${count}` : ''}</h5>
            </div>
            <div className="col col-6 text-right">
              <Link
                to="/message/new"
                className="btn btn-primary"
                data-testid="dashboard__new_message"
              >
                <i>+</i> Message
              </Link>
            </div>
          </div>
        </div>
        <MessageList
          dataLoaded={dataLoaded}
          list={
            messages && messages[messageTab] !== undefined
              ? messages[messageTab].slice(0, 3)
              : []
          }
          filter={messageTab}
          name="dashboard"
          togglePinUnpin={this.togglePinUnpin}
          readMessageThread={this.readMessageThread}
        />
        <div className="bottomSpacing text-right custom-page-link mb-4">
          <a
            className="text-dark text-uppercase"
            onClick={this.navigateUrl}
            data-testid="dashboard__all_messages"
          >
            See All Inbox Messages <i className="icon-right-arrow" />
          </a>
        </div>
      </section>
    );
  }

  renderMessageList() {
    const { messages } = this.props;
    const { dataLoaded } = this.state;

    let count;
    if (messages && messages.inbox && dataLoaded) {
      count = messages.inbox_count;
    }
    const alertCount =
      messages && messages.alert && dataLoaded ? messages.alert_count : '';

    if (isUserEmployee()) {
      return this.renderEmployeeView(count, alertCount);
    }

    return this.renderClientView(count);
  }

  render() {
    const MyTasksTotal =
      this.props.tasks.Review_total !== undefined && !this.state.taskLoading
        ? `| ${this.props.tasks.Review_total}`
        : '';
    const PendingTotal =
      this.props.tasks.Open_total !== undefined && !this.state.taskLoading
        ? `| ${this.props.tasks.Open_total}`
        : '';
    const { homedata, taskStatus } = this.state;
    const { location } = this.props;

    const {
      flags: { requestsPageFeature },
    } = this.props;

    return requestsPageFeature ? (
      <div>
        <header className="d-flex align-items-center">
          <h1 className="pageHeading">Dashboard</h1>
        </header>
        {/* <Alert html /> */}
        <div className="center-wrapper">
          {this.renderMessageList()}
          {getFirmType() && getFirmType() !== 'liscio-lite' ? (
            <section className="mb-5">
              {!homedata &&
                (!this.props.messages.inbox ||
                  JSON.stringify(this.props.messages)[0] !== '{') ? (
                <MessageContentLoader />
              ) : homedata && homedata.is_employee ? (
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <Link
                      onClick={() => this.changeStatus('Review')}
                      className={
                        taskStatus === 'Review' ? 'nav-link active' : 'nav-link'
                      }
                      data-toggle="tab"
                      to={(e) => e.preventDefault()}
                      data-testid="dashboard__my_tasks"
                    >
                      My Tasks {MyTasksTotal}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      onClick={() => this.changeStatus('Open')}
                      className={
                        taskStatus === 'Open' ? 'nav-link active' : 'nav-link'
                      }
                      data-toggle="tab"
                      to={(e) => e.preventDefault()}
                      data-testid="dashboard__outstanding_tasks"
                    >
                      Outstanding Tasks {PendingTotal}
                    </Link>
                  </li>
                </ul>
              ) : null}
              {homedata && homedata.is_employee ? (
                <>
                  <div className="tab-right-btn offset-1 position-relative pull-right text-right">
                    <Link
                      to="/task/new"
                      className="btn btn-primary"
                      state={{ from: location.pathname, page: 'dashboard' }}
                      data-testid="dashboard__new_task"
                    >
                      <i>+</i> Task
                    </Link>
                  </div>
                  <div className="tab-content">
                    <div className="tab-pane active" id="review">
                      <div id="inbox-list" className="tableWrap">
                        {this.renderList()}
                        <div className="text-right custom-page-link">
                          <Link
                            to="/all_tasks"
                            className="text-dark text-uppercase"
                            data-testid="dashboard__all_tasks"
                          >
                            See All Open Tasks{' '}
                            <i className="icon-right-arrow" />
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane" id="mytask">
                      <div className="emptyBox">
                        <div className="onlyText">
                          <p>My Task list goes here</p>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane" id="ihvAssigned">
                      <div className="emptyBox">
                        <div className="onlyText">
                          <p>I have assigned task list goes here</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Box mt={10} mb={2}>
                    <MyRequests />
                  </Box>
                </>
              ) : (
                <Box mt={10} mb={2}>
                  <MyTodoList />
                </Box>
              )}
            </section>
          ) : null}
        </div>
      </div>
    ) : (
      <div>
        <header className="d-flex align-items-center">
          <h1 className="pageHeading">Dashboard</h1>
        </header>
        {/* <Alert html /> */}
        <div className="center-wrapper">
          {this.renderMessageList()}
          {getFirmType() && getFirmType() !== 'liscio-lite' ? (
            <section className="mb-5">
              {!homedata &&
                (!this.props.messages.inbox ||
                  JSON.stringify(this.props.messages)[0] !== '{') ? (
                <MessageContentLoader />
              ) : homedata && homedata.is_employee ? (
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <Link
                      onClick={() => this.changeStatus('Review')}
                      className={
                        taskStatus === 'Review' ? 'nav-link active' : 'nav-link'
                      }
                      data-toggle="tab"
                      to={(e) => e.preventDefault()}
                      data-testid="dashboard__my_tasks"
                    >
                      My Tasks {MyTasksTotal}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      onClick={() => this.changeStatus('Open')}
                      className={
                        taskStatus === 'Open' ? 'nav-link active' : 'nav-link'
                      }
                      data-toggle="tab"
                      to={(e) => e.preventDefault()}
                      data-testid="dashboard__outstanding_tasks"
                    >
                      Outstanding Tasks {PendingTotal}
                    </Link>
                  </li>
                </ul>
              ) : (
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <Link
                      className="nav-link active"
                      data-toggle="tab"
                      to={(e) => e.preventDefault()}
                      data-testid="dashboard__my_tasks"
                    >
                      My Tasks {PendingTotal}
                    </Link>
                  </li>
                </ul>
              )}
              {homedata && homedata.is_employee ? (
                <div className="tab-right-btn offset-1 position-relative pull-right text-right">
                  <Link
                    to="/task/new"
                    className="btn btn-primary"
                    state={{ from: location.pathname, page: 'dashboard' }}
                    data-testid="dashboard__new_task"
                  >
                    <i>+</i> Task
                  </Link>
                </div>
              ) : null}
              <div className="tab-content">
                <div className="tab-pane active" id="review">
                  <div id="inbox-list" className="tableWrap">
                    {this.renderList()}
                    <div className="text-right custom-page-link">
                      <Link
                        to="/all_tasks"
                        className="text-dark text-uppercase"
                        data-testid="dashboard__all_tasks"
                      >
                        See All Open Tasks <i className="icon-right-arrow" />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="tab-pane" id="mytask">
                  <div className="emptyBox">
                    <div className="onlyText">
                      <p>My Task list goes here</p>
                    </div>
                  </div>
                </div>
                <div className="tab-pane" id="ihvAssigned">
                  <div className="emptyBox">
                    <div className="onlyText">
                      <p>I have assigned task list goes here</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tasks: state.taskReducer.tasks,
  homedata: state.homeReducer.data,
  messages: state.messageReducer.allMessages,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
  messageActions: bindActionCreators(messageActions, dispatch),
  PaginationActions: bindActionCreators(PaginationActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLDConsumer()(Dashboard));
