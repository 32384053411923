/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * API V5
 * OpenAPI spec version: v5
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  ExperienceArraySchema,
  ExperienceSchema,
  ExperienceStatusErrorSchema,
  PatchApiV5ExperiencesExperienceIdBody,
  Uuid
} from '.././schemas'
import getApiV5ExperiencesMutator from '../../configOrvalAPIV5';
import type { ErrorType as GetApiV5ExperiencesErrorType } from '../../configOrvalAPIV5';
import getApiV5ExperiencesExperienceIdMutator from '../../configOrvalAPIV5';
import type { ErrorType as GetApiV5ExperiencesExperienceIdErrorType } from '../../configOrvalAPIV5';
import patchApiV5ExperiencesExperienceIdMutator from '../../configOrvalAPIV5';
import type { ErrorType as PatchApiV5ExperiencesExperienceIdErrorType } from '../../configOrvalAPIV5';



/**
 * @summary Get All Experiences
 */
export const getApiV5Experiences = (
    
 signal?: AbortSignal
) => {
      
      
      return getApiV5ExperiencesMutator<ExperienceArraySchema>(
      {url: `/api/v5/experiences`, method: 'GET', signal
    },
      );
    }
  

export const getGetApiV5ExperiencesQueryKey = () => {
    return [`/api/v5/experiences`] as const;
    }

    
export const getGetApiV5ExperiencesQueryOptions = <TData = Awaited<ReturnType<typeof getApiV5Experiences>>, TError = GetApiV5ExperiencesErrorType<void>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV5Experiences>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV5ExperiencesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV5Experiences>>> = ({ signal }) => getApiV5Experiences(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV5Experiences>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV5ExperiencesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV5Experiences>>>
export type GetApiV5ExperiencesQueryError = GetApiV5ExperiencesErrorType<void>


/**
 * @summary Get All Experiences
 */

export function useGetApiV5Experiences<TData = Awaited<ReturnType<typeof getApiV5Experiences>>, TError = GetApiV5ExperiencesErrorType<void>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV5Experiences>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV5ExperiencesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Get a Single Experience
 */
export const getApiV5ExperiencesExperienceId = (
    experienceId: Uuid,
 signal?: AbortSignal
) => {
      
      
      return getApiV5ExperiencesExperienceIdMutator<ExperienceSchema>(
      {url: `/api/v5/experiences/${experienceId}`, method: 'GET', signal
    },
      );
    }
  

export const getGetApiV5ExperiencesExperienceIdQueryKey = (experienceId: Uuid,) => {
    return [`/api/v5/experiences/${experienceId}`] as const;
    }

    
export const getGetApiV5ExperiencesExperienceIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiV5ExperiencesExperienceId>>, TError = GetApiV5ExperiencesExperienceIdErrorType<void>>(experienceId: Uuid, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV5ExperiencesExperienceId>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV5ExperiencesExperienceIdQueryKey(experienceId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV5ExperiencesExperienceId>>> = ({ signal }) => getApiV5ExperiencesExperienceId(experienceId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(experienceId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV5ExperiencesExperienceId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV5ExperiencesExperienceIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV5ExperiencesExperienceId>>>
export type GetApiV5ExperiencesExperienceIdQueryError = GetApiV5ExperiencesExperienceIdErrorType<void>


/**
 * @summary Get a Single Experience
 */

export function useGetApiV5ExperiencesExperienceId<TData = Awaited<ReturnType<typeof getApiV5ExperiencesExperienceId>>, TError = GetApiV5ExperiencesExperienceIdErrorType<void>>(
 experienceId: Uuid, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV5ExperiencesExperienceId>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV5ExperiencesExperienceIdQueryOptions(experienceId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Update a Single Experience
 */
export const patchApiV5ExperiencesExperienceId = (
    experienceId: Uuid,
    patchApiV5ExperiencesExperienceIdBody: PatchApiV5ExperiencesExperienceIdBody,
 ) => {
      
      
      return patchApiV5ExperiencesExperienceIdMutator<ExperienceSchema>(
      {url: `/api/v5/experiences/${experienceId}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchApiV5ExperiencesExperienceIdBody
    },
      );
    }
  


export const getPatchApiV5ExperiencesExperienceIdMutationOptions = <TError = PatchApiV5ExperiencesExperienceIdErrorType<ExperienceStatusErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiV5ExperiencesExperienceId>>, TError,{experienceId: Uuid;data: PatchApiV5ExperiencesExperienceIdBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof patchApiV5ExperiencesExperienceId>>, TError,{experienceId: Uuid;data: PatchApiV5ExperiencesExperienceIdBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchApiV5ExperiencesExperienceId>>, {experienceId: Uuid;data: PatchApiV5ExperiencesExperienceIdBody}> = (props) => {
          const {experienceId,data} = props ?? {};

          return  patchApiV5ExperiencesExperienceId(experienceId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchApiV5ExperiencesExperienceIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiV5ExperiencesExperienceId>>>
    export type PatchApiV5ExperiencesExperienceIdMutationBody = PatchApiV5ExperiencesExperienceIdBody
    export type PatchApiV5ExperiencesExperienceIdMutationError = PatchApiV5ExperiencesExperienceIdErrorType<ExperienceStatusErrorSchema | void>

    /**
 * @summary Update a Single Experience
 */
export const usePatchApiV5ExperiencesExperienceId = <TError = PatchApiV5ExperiencesExperienceIdErrorType<ExperienceStatusErrorSchema | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiV5ExperiencesExperienceId>>, TError,{experienceId: Uuid;data: PatchApiV5ExperiencesExperienceIdBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof patchApiV5ExperiencesExperienceId>>,
        TError,
        {experienceId: Uuid;data: PatchApiV5ExperiencesExperienceIdBody},
        TContext
      > => {

      const mutationOptions = getPatchApiV5ExperiencesExperienceIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    