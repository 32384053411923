import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDropzone, FileRejection } from 'react-dropzone';
import { useActiveStorageFileUploader } from 'hooks/useActiveStorageFileUploader';
import { Upload } from '@mui/icons-material';
import { EmployeeSelect } from 'common/organisms/EmployeeSelect/EmployeeSelect';
import { Employee } from 'api/employees';
import { getStorageData } from 'utilities/utils';
import { usePostWorkflowRPARequest } from 'hooks/workflow-request-hooks';
import { useGetUserFromLocalStorage } from 'hooks/useGetUserFromLocalStorage';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { pluralize } from 'utilities/pluralize';
import { DropBoxType } from 'components/Requests/requests.types';
import { useGetWorkflowCounts } from 'components/Requests/hooks/get-workflow-counts';
import { useNavigate } from 'react-router-dom-latest';
import { REQUESTS_PAGE_ROUTES } from 'components/Requests/requests.constants';
import { DropBox } from './DropBox';

interface UploadRPAFilesProps {
  onClose?: () => void;
}

type FileWithRpaType = File & { rpa_type: DropBoxType };

const Banner = styled(Stack)({
  display: 'grid',
  justifyItems: 'center',
  margin: '1rem',
  padding: '1rem',
  backgroundColor: '#2061a1',
});

export default function UploadRPAFiles({ onClose }: UploadRPAFilesProps) {
  const navigate = useNavigate();
  const { setPollRPADocuments } = useGetWorkflowCounts();
  const { rpAimportCoreUserBanner, returnUpload } = useFlags();
  const [reactDnDErrorFiles, setReactDnDErrorFiles] = useState<FileRejection[]>(
    [],
  );
  const [uploadedMetadataFiles, setUploadedMetadataFiles] = useState<any[]>([]);
  const [cpaUserId, setCpaUserId] = useState<number>(0);
  const rpaTypeRef = useRef<DropBoxType>('organizer');
  const dedupeListRef = useRef<FileWithRpaType[]>([]);

  const { storedUser } = useGetUserFromLocalStorage();
  const {
    userpilot_data: { cpa_license_tier: cpaLicenseTier },
  } = storedUser;

  // use mutateAsync as we need it to resolve/reject in the Promise.allSettled loop
  // https://tanstack.com/query/v4/docs/react/guides/mutations#promises
  const {
    mutateAsync: postWorkflowRPARequestMutation,
    isLoading: postWorkflowRPARequestMutationIsLoading,
  } = usePostWorkflowRPARequest();

  useEffect(() => {
    document.title = 'Upload Tax Files';
  }, []);

  useEffect(() => {
    const user = getStorageData();
    if (user?.cpa_user_id) setCpaUserId(user.cpa_user_id);
  }, []);

  const {
    files,
    uploadedFiles,
    isUploading,
    addFiles,
    removeFile,
    uploadFiles,
    uploadStatus,
  } = useActiveStorageFileUploader();

  const addRpaType = (file: File): FileWithRpaType => {
    const obj: FileWithRpaType = file as FileWithRpaType;
    obj.rpa_type = rpaTypeRef.current;
    return obj;
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: { 'application/pdf': ['.pdf'] },
    onDrop: (accepted, rejected) => {
      if (rejected) {
        setReactDnDErrorFiles(rejected);
      }
      if (accepted) {
        // filter out any files with the same name
        const dedupeAccepted = accepted
          .filter((file) => !files.find((f) => f.name === file.name))
          .map(addRpaType);

        dedupeListRef.current?.push(...dedupeAccepted);
        addFiles(dedupeAccepted);
      }
    },
  });

  const handleClearInvalidFileTypeErrors = () => {
    setReactDnDErrorFiles([]);
  };

  const handleUploadButtonClick = () => {
    if (uploadButtonDisabled) return;
    uploadFiles();
  };

  const handleRemoveFile = (fileIndex: number) => {
    dedupeListRef.current.splice(fileIndex, 1);
    removeFile(fileIndex);
  };

  const handleSetCpaUserId = (employee: Employee) => {
    setCpaUserId(employee.cpa_user_id);
  };

  const hasFiles = useMemo(() => files.length > 0, [files]);

  const hasReactDnDErrorFiles = useMemo(
    () => reactDnDErrorFiles.length > 0,
    [reactDnDErrorFiles],
  );

  const uploadButtonDisabled = useMemo(() => {
    if (!hasFiles || hasReactDnDErrorFiles || isUploading || !cpaUserId)
      return true;
    return false;
  }, [isUploading, hasFiles, hasReactDnDErrorFiles, cpaUserId]);

  const uploadSuccessCountText = useMemo(() => {
    const numberOfSuccessfulUploads = uploadedFiles.filter(
      (file: any) => !file?.error,
    ).length;
    const numberOfTotalFiles = files.length;
    return `${numberOfSuccessfulUploads} of ${numberOfTotalFiles}`;
  }, [files, uploadedFiles]);

  const uploadedMetadataSuccessCount = useMemo(
    () => uploadedMetadataFiles.filter((upload: any) => upload?.value).length,
    [uploadedMetadataFiles],
  );

  const showUploadMetaStatusFailed = useMemo(
    () =>
      uploadStatus.success &&
      !postWorkflowRPARequestMutationIsLoading &&
      uploadedMetadataSuccessCount !== uploadStatus.successCount,
    [
      postWorkflowRPARequestMutationIsLoading,
      uploadStatus.success,
      uploadStatus.successCount,
      uploadedMetadataSuccessCount,
    ],
  );

  const uploadFileMetadata = useCallback(
    async (uFiles: any[]) => {
      const results = await Promise.allSettled(
        uFiles.map(
          (file) =>
            !file.error &&
            postWorkflowRPARequestMutation({
              rpaType: dedupeListRef.current?.find(
                (dItem) => dItem.name === file.filename,
              )?.rpa_type as DropBoxType,
              signedBlobId: file.signed_id,
              cpaUserId: cpaUserId as number,
            }),
        ),
      );
      setUploadedMetadataFiles(results);
      dedupeListRef.current = [];
      setPollRPADocuments(true);
      return results;
    },
    [cpaUserId, postWorkflowRPARequestMutation, setPollRPADocuments],
  );

  // POST the signed_blob_id and cpa_user_id to the RPA endpoint
  useEffect(() => {
    if (uploadStatus.success) {
      uploadFileMetadata(uploadedFiles);
    }
  }, [uploadFileMetadata, uploadStatus.success, uploadedFiles]);

  // Close action depending on if this component is being used in a modal or page
  const handleOnClose = () => {
    if (onClose) {
      onClose();
    } else {
      navigate(REQUESTS_PAGE_ROUTES.requests);
    }
  };

  return (
    <>
      <Box
        sx={{
          overflow: 'auto',
          flex: 1,
          paddingTop: 4,
          paddingLeft: 14,
          paddingRight: 14,
        }}
      >
        {rpAimportCoreUserBanner && cpaLicenseTier === 'core' ? (
          <Banner gap={1}>
            <Typography
              variant="h6"
              sx={(theme) => ({
                fontWeight: 'bold',
                color: `${theme.palette.primary.contrastText}`,
              })}
            >
              Special Access to Pro Tier Feature: Automated Custom Tax
              Organizers.
            </Typography>
            <Typography
              variant="body1"
              sx={(theme) => ({
                color: `${theme.palette.primary.contrastText}`,
              })}
            >
              As Core Tier users, your firm can send 5 requests before making
              the leap to Pro Tier.
            </Typography>
          </Banner>
        ) : null}
        <Grid container spacing={1} justifyContent="space-between">
          {/* Show file upload area */}
          {!returnUpload && !isUploading && uploadedFiles.length < 1 && (
            <Grid item xs={12}>
              <DropBox
                dropBoxType="original"
                rootProps={getRootProps}
                inputProps={getInputProps()}
                setRpaType={(type) => {
                  rpaTypeRef.current = type;
                }}
              />
            </Grid>
          )}
          {returnUpload && !isUploading && uploadedFiles.length < 1 && (
            <>
              <Grid item xs={6} display="flex">
                <Box width="100%">
                  <DropBox
                    dropBoxType="organizer"
                    rootProps={getRootProps}
                    inputProps={getInputProps()}
                    setRpaType={(type) => {
                      rpaTypeRef.current = type;
                    }}
                    key={1}
                  />
                  <Box width="90%">
                    <Typography
                      variant="h6"
                      align="center"
                      sx={{
                        fontSize: '18px',
                        margin: '5px 20px 0px 20px',
                      }}
                    >
                      Use only standard 2024 organizers from supported tax
                      systems. Limit 100 files per batch. Do not use custom or
                      prior year organizers.{' '}
                      <a
                        href="https://support.liscio.me/portal/en/kb/articles/organizers-importing-8-1-2024"
                        target="_blank"
                        rel="noreferrer"
                      >
                        See supported organizers.
                      </a>
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} display="flex">
                <Box width="100%">
                  <DropBox
                    dropBoxType="tax_delivery"
                    rootProps={getRootProps}
                    inputProps={getInputProps()}
                    setRpaType={(type) => {
                      rpaTypeRef.current = type;
                    }}
                    key={2}
                  />
                  <Box width="90%">
                    <Typography
                      variant="h6"
                      align="center"
                      sx={{
                        fontSize: '18px',
                        margin: '5px 20px 0px 20px',
                      }}
                    >
                      Use only 2023 or 2024 client copies of 1040 returns from
                      supported tax systems here.{' '}
                      <a
                        href="https://support.liscio.me/portal/en/kb/articles/tax-return-delivery-sending-the-tax-return-request"
                        target="_blank"
                        rel="noreferrer"
                      >
                        See supported returns.
                      </a>
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </>
          )}
          {/* Show upload failed messaging */}
          {!isUploading &&
            uploadStatus.successCount === 0 &&
            uploadStatus.success && (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                height="200px"
                width="100%"
              >
                <Box
                  sx={(theme) => ({
                    backgroundColor: theme.palette.grey[800],
                    borderRadius: '10px',
                    width: '700px',
                    height: '100px',
                    margin: '0px',
                    padding: '35px 60px',
                  })}
                >
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    color="white"
                    sx={{ margin: '0px 0px 5px 0px' }}
                  >
                    Error uploading files. Please try again or contact support.
                  </Typography>
                </Box>
              </Stack>
            )}
          {/* Show upload non-failed status messaging */}
          {(isUploading || uploadStatus.successCount > 0) && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              height="200px"
              width="100%"
            >
              <Box
                sx={(theme) => ({
                  backgroundColor: theme.palette.grey[800],
                  borderRadius: '10px',
                  width: '700px',
                  height: uploadStatus.success ? '200px' : '100px',
                  margin: uploadStatus.success ? '40px 20px 0px 20px' : '0px',
                  padding: '35px 60px',
                })}
              >
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  color="white"
                  sx={{ margin: '0px 0px 5px 0px' }}
                >
                  {uploadStatus.success
                    ? "We're creating your requests..."
                    : 'Please do not leave this page while files are uploading.'}
                </Typography>
                <Typography variant="body1" color="white">
                  {uploadStatus.success &&
                    `Processing your files may take a while. You can leave this page. Processed files are automatically converted to requests and shown as drafts on the Requests page.`}
                </Typography>
              </Box>
            </Stack>
          )}
          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="center"
              sx={{
                padding: 4,
                overflowY: 'auto',
              }}
            >
              <Stack spacing={0} sx={{ width: '100%' }}>
                {/* Attempting to upload all files to the S3 bucket */}
                {isUploading && (
                  <Alert severity="info" icon={<CircularProgress size={16} />}>
                    <AlertTitle>
                      Uploading {uploadStatus.successCount + 1} of{' '}
                      {files.length}
                    </AlertTitle>
                  </Alert>
                )}
                {/* We uploaded X number files to the S3 bucket */}
                {uploadStatus.success && (
                  <Alert
                    severity={uploadStatus.errorCount ? 'warning' : 'success'}
                  >
                    <AlertTitle>
                      Uploaded {uploadSuccessCountText} files
                    </AlertTitle>
                  </Alert>
                )}
                {/* If we failed to upload some of the metadata to the RPA endpoint */}
                {showUploadMetaStatusFailed && (
                  <Alert severity="warning" sx={{ marginTop: '8px' }}>
                    <AlertTitle>
                      Error finalizing{' '}
                      {uploadedMetadataFiles.length -
                        uploadedMetadataSuccessCount}{' '}
                      file{' '}
                      {pluralize(
                        'upload',
                        uploadedMetadataFiles.length -
                          uploadedMetadataSuccessCount,
                      )}
                    </AlertTitle>
                  </Alert>
                )}
                {/* Show errors if the users is trying to upload a file type that is not a PDF */}
                {reactDnDErrorFiles.length > 0 && (
                  <Alert
                    severity="error"
                    action={
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={handleClearInvalidFileTypeErrors}
                        sx={{ fontSize: '12px', paddingRight: '16px' }}
                      >
                        <i className="icon-close2" aria-hidden="true" />
                      </IconButton>
                    }
                  >
                    <AlertTitle>Error</AlertTitle>
                    {reactDnDErrorFiles.map((file) => (
                      <p key={file.file.name}>
                        {file.file.name} - {file.errors[0].message}
                      </p>
                    ))}
                  </Alert>
                )}
                {/* Show files ready for upload and success/error state for attempted uploads */}
                {hasFiles && (
                  <List>
                    {files.map((file, index) => (
                      <ListItem
                        key={`${file}`}
                        sx={(theme) => ({
                          backgroundColor: theme.palette.grey[100],
                          borderRadius: '3px',
                          marginTop: '8px',
                        })}
                        secondaryAction={
                          uploadedFiles.find(
                            (uploadedFile: any) =>
                              uploadedFile?.filename === file.name &&
                              !uploadedFile?.error,
                          ) ? (
                            <i
                              className="icon-tickmark_trans_o"
                              aria-hidden="true"
                            />
                          ) : (
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => handleRemoveFile(index)}
                              sx={{ fontSize: '12px' }}
                            >
                              {isUploading ? (
                                'Uploading...'
                              ) : (
                                <i className="icon-close2" aria-hidden="true" />
                              )}
                            </IconButton>
                          )
                        }
                      >
                        <ListItemIcon>
                          <i className="icon-filetype-pdf" aria-hidden="true" />
                        </ListItemIcon>
                        <ListItemText primary={file.name} />
                      </ListItem>
                    ))}
                  </List>
                )}
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      {/* Bottom/Footer area */}
      <Box
        sx={(theme) => ({
          borderTop: `1px solid ${theme.palette.grey[200]}`,
          padding: '0 20px',
          backgroundColor: theme.palette.background.paper,
        })}
      >
        <Stack
          height="60px"
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          gap={2}
        >
          <Typography
            variant="body1"
            color="GrayText"
            flex={1}
            textAlign="right"
          >
            The owner assigned will receive all notifications for the generated
            workflow requests.
          </Typography>
          <Box>
            <Stack direction="row" alignItems="center" justifyContent="end">
              <Box width="200px" component="span" mr={2}>
                <EmployeeSelect
                  className="no-class"
                  defaultValueByCPAUserId={cpaUserId}
                  placeholder="Select an owner"
                  onChange={(emp) => emp && handleSetCpaUserId(emp)}
                />
              </Box>
              {uploadStatus.success ? (
                <Button
                  onClick={handleOnClose}
                  color="primary"
                  sx={{ minWidth: '125px' }}
                  variant="contained"
                >
                  View processing
                </Button>
              ) : (
                <Button
                  onClick={handleUploadButtonClick}
                  color="primary"
                  sx={{ minWidth: '150px' }}
                  variant="contained"
                  startIcon={
                    isUploading ? <CircularProgress size={16} /> : <Upload />
                  }
                  disabled={uploadButtonDisabled}
                >
                  {isUploading ? 'Uploading...' : 'Begin Upload'}
                </Button>
              )}
            </Stack>
          </Box>
        </Stack>
      </Box>
    </>
  );
}
