// https://pspdfkit.com/api/web/
// https://github.com/PSPDFKit/awesome-pspdfkit
// make sure you have the PSPDFKit license key(VITE_PSPDFKIT_LICENSE_KEY) in your .env file

// when updating PSPDFKit version you need to copy files from the node_modules/pspdfkit/dist folder to the public folder
// 1. Delete public/pspdfkit-lib folder
// 2. cp -R ./node_modules/pspdfkit/dist/pspdfkit-lib public/pspdfkit-lib

import { useEffect, useRef } from 'react';

import PSPDFKit, { Configuration, ToolbarItem } from 'pspdfkit';
import { LiscioPSPDFKitInstance } from 'types/global';
import { useGetUserFromLocalStorage } from 'hooks/useGetUserFromLocalStorage';
import {
  basicToolbarItems,
  basicToolbarItemsWithAnnotations,
} from './toolbarItems';

type PDFViewerProps = {
  /** The URL of the PDF document to be displayed */
  url: string;
  /** Don't allow annotations and editing
   * @default true
   * */
  readOnly?: boolean;
  /** This is a list of items to override the default toolbar items.
   * @default basicToolbarItems
   * */
  toolbarItems?: ToolbarItem[];
  /** PSPDFKit configuration options */
  configurationsOptions?: Configuration;
  fileName: string;
};

// TODO: This popped up after a PSPDFKit update. Need to investigate.
// setting this to true allows us to change the viewState to a non-readonly state (needed for annotations)
// BUUUT can only be called once or the PSPDFKit instance will crash
// https://liscio.atlassian.net/browse/DEV-5592
// https://liscio.atlassian.net/browse/DEV-5637
if (!window.PSPDFKIT_INSTANCE && PSPDFKit.Options) {
  PSPDFKit.Options.IGNORE_DOCUMENT_PERMISSIONS = true;
}

export default function PDFViewer({
  url,
  readOnly = true,
  toolbarItems,
  fileName,
  configurationsOptions,
}: PDFViewerProps) {
  const containerRef = useRef(null);

  // Pro tier users can see annotations and may get other special features down the road.
  const { storedUser } = useGetUserFromLocalStorage();
  const {
    userpilot_data: { cpa_license_tier: cpaLicenseTier },
  } = storedUser;
  const isProTierUser = cpaLicenseTier?.toLowerCase() === 'pro';

  useEffect(() => {
    const container = containerRef.current; // This `useRef` instance will render the PDF.

    let instance: LiscioPSPDFKitInstance;
    // eslint-disable-next-line
    (async function () {
      PSPDFKit.unload(container); // Ensure that there's only one PSPDFKit instance.

      // https://pspdfkit.com/api/web/PSPDFKit.Configuration.html
      instance = await PSPDFKit.load({
        licenseKey: process.env.REACT_APP_PSPDFKIT_LICENSE_KEY,
        container: container as unknown as HTMLElement,
        document: url,
        baseUrl: `${window.location.protocol}//${window.location.host}/`,
        printMode: PSPDFKit.PrintMode.EXPORT_PDF, //https://pspdfkit.com/guides/web/print/print-modes/#pspdfkit-printmode-export_pdf // this is going to be deprecated, investigate

        ...configurationsOptions,
      });
      // https://pspdfkit.com/api/web/PSPDFKit.ViewState.html
      instance.setViewState((viewState) =>
        isProTierUser
          ? viewState.set('enableAnnotationToolbar', true)
          : viewState.set('readOnly', readOnly),
      );

      // Expose the PSPDFKit instance to the window object.
      window.PSPDFKIT_INSTANCE = instance;
      // Set our custom properties on the PSPDFKit instance.
      window.PSPDFKIT_INSTANCE.fileName = fileName;
      window.PSPDFKIT_INSTANCE.hideAllAnnotations = false;

      // Set/remove/add custom toolbar items.
      // https://pspdfkit.com/api/web/PSPDFKit.Instance.html#setToolbarItems
      if (toolbarItems) {
        instance.setToolbarItems(toolbarItems);
      } else if (isProTierUser) {
        instance.setToolbarItems(basicToolbarItemsWithAnnotations);
      } else {
        instance.setToolbarItems(basicToolbarItems);
      }
    })();

    // cleanup the PSPDFKit instance
    return () => {
      window.PSPDFKIT_INSTANCE = undefined;
      PSPDFKit?.unload(container);
    };
  }, [
    configurationsOptions,
    readOnly,
    toolbarItems,
    url,
    fileName,
    isProTierUser,
  ]);

  // This div element will render the document to the DOM.
  return (
    <div ref={containerRef} style={{ width: '100%', height: 'inherit' }} />
  );
}
