import MUITabs, { TabsProps as MUITabsProps } from '@mui/material/Tabs';
import { styled } from '@mui/material/styles';

interface IStyledTabs {
  noBorder?: boolean;
}
const StyledTabs = styled(MUITabs, {
  shouldForwardProp: (prop) => prop !== 'noBorder',
})<IStyledTabs>(({ noBorder }) => ({
  borderBottom: noBorder ? 'none' : '1px solid #dfe3e8',
  '& .MuiTabs-indicator': {
    backgroundColor: '#212b36',
  },
}));

type TabsProps = {
  noBorder?: boolean;
} & MUITabsProps;

export const Tabs = (props: TabsProps) => <StyledTabs {...props} />;
