/* eslint-disable @typescript-eslint/no-shadow */

import React, { useState, useEffect, useMemo } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom-latest';
import styled from '@emotion/styled';
import Axios from 'axios';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getSiteHostname,
  getStorageData,
  setUserInLocalStorage,
} from 'utilities/utils';
import {
  GET_TERM_CONDITIONS,
  HOME_API_PATH,
  MIXPANEL_URL_EVENT,
} from 'constants/constants';
import {
  trackMixpanelEvent,
  registerSuperProperties,
} from 'Mixpanel/mixpanelfn';
import * as NotificationActions from 'actions/NotificationActions';
import * as actions from 'actions/PaginationActions';
import * as HomeActions from 'actions/HomeActions';
import Sidebar from 'components/Sidebar';
import TermConditions from 'common/TermsConditions_hooks_updated';
import { useLaunchDarklyIdentify } from 'hooks/useLaunchDarklyIdentify';
import { initGraphQLClient } from 'hooks/useGraphQL';
import { getAuthSession } from 'auth/session';
import OutofOfficeBanner from './OutofOffice/OutofOfficeBanner';
import 'scss/all.scss';
import SMSNotificationBar from './SMS/components/SMSNotificationBar/SMSNotificationBar';

import './axios-interceptors';

const StyledMain = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  .emailWrapscroll {
    /* padding-bottom: 60px; */
  }
  .Main__Content {
    height: 100vh;
    display: flex;
    flex-direction: row;
  }
`;

// eslint-disable-next-line no-shadow
const Main = ({ NotificationActions, saveHomeData }) => {
  const location = useLocation();
  const [homeData, setHomeData] = useState();
  const [termsData, setTermsData] = useState({});
  const [identifiedUser, setIdentifiedUser] = useState(false);
  const [previousLocation, setPreviousLocation] = useState(location.pathname);
  const [previousSearch, setPreviousSearch] = useState(location.search);
  const [previousHash, setPreviousHash] = useState(location.hash);
  const navigate = useNavigate();
  const { identify } = useLaunchDarklyIdentify();

  const local_data = getStorageData();

  const hasSmsEnabled = useMemo(
    () => homeData?.cpa_sms_enabled || false,
    [homeData],
  );

  useEffect(() => {
    getTermConditions();
    getHomeData(identify);
    if (localStorage.getItem('path') && localStorage.getItem('search')) {
      const path = localStorage.getItem('path');
      const search = localStorage.getItem('search');
      const full_path = path + search;
      localStorage.removeItem('path');
      localStorage.removeItem('search');
      navigate(`${full_path}`);
    }
    if (localStorage.getItem('emailsUrl')) {
      localStorage.removeItem('emailsUrl');
    }
  }, []);

  // componentDidUpdate
  useEffect(() => {
    const { userpilot } = window;
    setPreviousLocation(location.pathname);
    setPreviousSearch(location.search);
    setPreviousHash(location.hash);

    // TODO: __HOMEDATA-AUDIT
    const userData =
      localStorage.getItem(`${getSiteHostname()} data`) !== null
        ? localStorage.getItem(`${getSiteHostname()} data`)
        : undefined;

    identifyUser(userData);

    if (
      userData &&
      userpilot &&
      (previousLocation !== location.pathname ||
        previousSearch !== location.search ||
        location.hash !== previousHash)
    ) {
      userpilot.reload();
    }
    trackUrlEvent();
  }, [location.pathname, homeData]);

  function trackUrlEvent() {
    const { mixpanel } = window;
    if (mixpanel) {
      trackMixpanelEvent(MIXPANEL_URL_EVENT, {
        path: parseUrl(location.pathname),
      });
    }
  }

  // removes id specific numbers from url and replaces them with ':id'
  function parseUrl(path) {
    let url = '';
    const array = path.split('/');
    const len = array.length;
    let i = 0;
    array.forEach((key) => {
      if (key === '' || Number.isNaN(Number(key))) {
        url += key;
      } else {
        url += ':id';
      }
      if (i !== len - 1) {
        url += '/';
      }
      i += 1;
    });
    return url;
  }

  function identifyUser(userData) {
    // we identify user in mixpanel and userpilot only once
    if (!identifiedUser && userData) {
      // TODO: __HOMEDATA-AUDIT
      const { userpilot_data } = JSON.parse(userData);
      const { userpilot } = window;
      // identify user in userpilot
      if (userpilot && userpilot_data) {
        const uniqueId = `${userpilot_data.unique_id}_${userpilot_data.app_name}_${userpilot_data.firm_name}`;
        userpilot_data.name = userpilot_data.user_name;
        userpilot.identify(uniqueId, userpilot_data);
        setIdentifiedUser(true);
      }
    }
  }

  async function getTermConditions() {
    if (getAuthSession()) {
      try {
        const response = await Axios.get(GET_TERM_CONDITIONS);
        if (response.status === 200 && Object.keys(response.data.data).length) {
          setTermsData(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  async function getHomeData(identifyFn) {
    if (getAuthSession()) {
      // we need an auth_token to init the graphql client
      initGraphQLClient();

      try {
        // TODO: __HOMEDATA-AUDIT
        const response = await Axios.get(HOME_API_PATH);
        if (response.status === 200 && Object.keys(response.data.data).length) {
          const stored = response.data.data;
          stored.smsPhoneLine = response.data.smsPhoneLine;
          stored.is_multiple_cpa = response.data.is_multiple_cpa;
          stored.multiple_cpa_data = response.data.cpa_data;
          NotificationActions.isMultipleCpa(response.data.is_multiple_cpa);
          NotificationActions.multipleCpaData(response.data.cpa_data);
          NotificationActions.isAway(response.data.is_away);
          NotificationActions.awayStartDate(
            response.data.away_start_date
              ? response.data.away_start_date
              : null,
          );
          NotificationActions.awayEndDate(
            response.data.away_end_date ? response.data.away_end_date : null,
          );
          localStorage.setItem(
            'release',
            JSON.stringify(response.data.release_version),
          );
          setUserInLocalStorage(stored);
          window.dispatchEvent(new Event('storage'));
          setHomeData(stored);
          saveHomeData(response.data.data);
          registerSuperProperties({});
          // Identify context for Launch Darkly
          if (identifyFn && typeof identifyFn === 'function') {
            await identifyFn(response?.data?.data);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  function agreedTerms() {
    const termState = termsData;

    termState.agreed.has_agreed = true;
    termState.agreed.agreed_terms_version = 0;
    termState.data.version_number = 0;

    setTermsData({
      ...termState,
      ...{ agreed: { has_agreed: true, agreed_terms_version: 0 } },
    });
  }

  const showTermsComp =
    termsData &&
    Object.keys(termsData).length &&
    (!termsData.agreed.has_agreed ||
      termsData.agreed.agreed_terms_version !== termsData.data.version_number);

  const showTermsAndConditions =
    showTermsComp &&
    location.pathname !== '/client_terms' &&
    location.pathname !== '/privacy_policy';
  const showLoading =
    location.pathname !== '/' &&
    location.pathname !== 'dashboard' &&
    !local_data;

  return (
    <div className="AppContainer" style={{ display: 'flex', width: '100%' }}>
      {showTermsAndConditions && (
        <TermConditions data={{ termsData }} agreedTerms={agreedTerms} />
      )}
      {!showTermsAndConditions && !showLoading && (
        <StyledMain className="Main">
          <SMSNotificationBar />
          <Sidebar hasSmsEnabled={hasSmsEnabled} />
          {/* Convert this to styled component */}
          <div
            style={{
              flexDirection: 'column',
              width: '100%',
              height: '100%',
              overflow: 'auto',
              position: 'relative',
            }}
          >
            <article
              className="emailWrapscroll"
              style={{ flex: 1, height: '100%' }}
            >
              <OutofOfficeBanner />
              <Outlet />
            </article>
          </div>
        </StyledMain>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAway: state.notificationReducer.isAway,
  awayStartDate: state.notificationReducer.awayStartDate,
  awayEndDate: state.notificationReducer.awayEndDate,
  updateAwaySetting: state.notificationReducer.updateAwaySetting,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
  NotificationActions: bindActionCreators(NotificationActions, dispatch),
  saveHomeData: (data) => dispatch(HomeActions.fetchAll(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
